<template>
  <component
    :is="componentType"
    :class="[
      'button',
      {
        'skeleton-loading': this.skeletonLoader,
        ...(this.typeTheme && { [`-${this.typeTheme}`]: this.typeTheme }),
        ...(this.size && { [`-${this.size}`]: this.size }),
        ...(this.state && { [`-${this.state}`]: this.state }),
        ...(this.subtype && { [`-${this.subtype}`]: this.subtype }),
        '-disabled': isDisabled,
      },
    ]"
    v-bind="$attrs"
    :disabled="isDisabled"
    :aria-disabled="isDisabled"
    :to="to"
    :href="href"
    :target="target"
    v-on="$listeners"
  >
    <Spinner v-if="loading" aria-role="progressbar" />

    <div v-if="icon" class="icon-wrapper">
      <Icon :name="icon" :size="['big_cta', 'large'].includes(size) ? '1.5rem' : '1.25rem'" />
    </div>

    <span v-if="label">
      {{ label }}
    </span>
    <slot v-else />

    <div v-if="iconRight" class="icon-wrapper">
      <Icon :name="iconRight" :size="['big_cta', 'large'].includes(size) ? '1.5rem' : '1.25rem'" />
    </div>
  </component>
</template>

<script>
// Components
import Spinner from '@/components/Spinner'
import Icon from '@/components/Icon'

export const TYPESTHEMES = [
  'primary-light',
  'primary-dark',
  'secondary-light',
  'secondary-dark',
  'ghost-light',
  'ghost-dark',
  'inline-primary',
  'inline-secondary',
]
export const SIZES = ['big_cta', 'large', 'medium', 'small']
export const STATES = ['enabled', 'disabled']
export const SUBTYPES = ['only-text', 'icon-text', 'only-icon', 'fab']

export default {
  name: 'Button',
  components: {
    Spinner,
    Icon,
  },
  props: {
    typeTheme: {
      type: String,
      validator: (value) => TYPESTHEMES.includes(value),
      required: true,
    },
    size: {
      type: String,
      validator: (value) => SIZES.includes(value),
      required: false,
      default: 'large',
    },
    state: {
      type: String,
      validator: (value) => STATES.includes(value),
      required: false,
      default: 'enabled',
    },
    subtype: {
      type: String,
      validator: (value) => SUBTYPES.includes(value),
      required: false,
      default: 'only-text',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    href: {
      type: String,
      required: false,
      default: undefined,
    },
    to: {
      type: String || Object,
      required: false,
      default: undefined,
    },
    icon: {
      type: String,
      required: false,
      default: undefined,
    },
    iconRight: {
      type: String,
      required: false,
      default: undefined,
    },
    redirectOutside: {
      type: Boolean,
      required: false,
      default: false,
    },
    skeletonLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    componentType() {
      if (this.href) return 'a'
      if (this.to) return 'NuxtLink'
      return 'button'
    },
    isDisabled() {
      return this.loading || this.disabled || this.state === 'disabled'
    },
    target() {
      return this.redirectOutside ? '_blank' : '_self'
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  border: 0.125rem solid transparent;
  border-radius: 0.5rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-align: center;
  text-decoration: none !important;
  white-space: nowrap;
  cursor: pointer;
  appearance: none;
  gap: 0.5rem;

  .icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 1.25rem;
  }

  span {
    overflow: hidden;
    text-decoration: none !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover,
  &:active {
    text-decoration: none !important;
  }

  // Sizing

  &.-big_cta {
    padding: 1.25rem 3rem;
    color: $button-primary-txt;
    font-size: 1.125rem;
    font-weight: 700;
  }

  &.-large {
    padding: 0.875rem 1.375rem;
    color: $button-primary-txt;
    font-size: 1rem;
  }

  &.-medium {
    padding: 0.5rem 0.875rem;
    color: $button-primary-txt;
    font-size: 0.875rem;
  }

  &.-small {
    padding: 0.25rem 0.375rem;
    color: $button-primary-txt;
    font-size: 0.875rem;
  }

  // Theming

  &.-primary-light {
    border: 0.125rem solid $button-primary-enabled;
    background-color: $button-primary-enabled;
    text-transform: uppercase;

    &:hover {
      border: 0.125rem solid $button-primary-hovered;
      background-color: $button-primary-hovered;
    }

    &:active {
      border: 0.125rem solid $button-primary-pressed;
      background-color: $button-primary-pressed;
    }
  }

  &.-primary-dark {
    border: 0.125rem solid $button-primary-dark-border;
    background-color: $button-primary-dark-enabled;
    text-transform: uppercase;

    &:hover {
      background-color: $button-primary-dark-hovered;
    }

    &:active {
      background-color: $button-primary-dark-pressed;
    }
  }

  &.-secondary-light {
    border: 0.125rem solid $button-secondary-border;
    background-color: $button-secondary-enabled;
    text-transform: uppercase;

    &:hover {
      background-color: $button-secondary-hovered;
    }

    &:active {
      background-color: $button-secondary-pressed;
    }

    &.-big_cta {
      font-weight: 500;
    }
  }

  &.-secondary-dark {
    border: 0.125rem solid $button-secondary-dark-border;
    background-color: $button-secondary-dark-enabled;
    color: $button-secondary-dark-txt;
    text-transform: uppercase;

    &:hover {
      background-color: $button-secondary-dark-hovered;
    }

    &:active {
      background-color: $button-secondary-dark-pressed;
    }

    &.-big_cta {
      font-weight: 500;
    }
  }

  &.-ghost-light {
    border-color: $button-ghost-enabled;
    background-color: $button-ghost-enabled;
    color: $button-ghost-txt;
    text-transform: uppercase;

    @include size-l-up {
      &:hover {
        border-color: $button-ghost-hovered;
        background-color: $button-ghost-hovered;
      }
    }

    &:active {
      border-color: $button-ghost-pressed;
      background-color: $button-ghost-pressed;
    }
  }

  &.-ghost-dark {
    border-color: $button-secondary-ghost-dark-enabled;
    background-color: $button-secondary-ghost-dark-enabled;
    color: $button-secondary-ghost-dark-txt-enabled;
    text-transform: uppercase;

    &:hover {
      border-color: $button-secondary-ghost-dark-hovered;
      background-color: $button-secondary-ghost-dark-hovered;
      color: $button-secondary-ghost-dark-txt-hovered;
    }

    &:active {
      border-color: $button-secondary-ghost-dark-pressed;
      background-color: $button-secondary-ghost-dark-pressed;
      color: $button-secondary-ghost-dark-txt-pressed;
    }
  }

  &.-inline-primary {
    padding: 0;
    border: 0;
    background-color: transparent;
    color: $link-primary-enabled;
    text-decoration: underline;

    &:hover {
      color: $link-primary-hovered;
    }

    &:active {
      color: $link-primary-Pressed;
    }
  }

  &.-inline-secondary {
    padding: 0;
    border: 0;
    background-color: transparent;
    color: $link-secondary-enabled;
    text-decoration: underline;

    &:hover {
      color: $link-secondary-hovered;
    }

    &:active {
      color: $link-secondary-pressed;
    }
  }

  &.-disabled {
    border-color: $disabled-01 !important;
    background-color: $disabled-01 !important;
    color: $disabled-02 !important;
    pointer-events: none;
  }

  &.-only-icon {
    aspect-ratio: 1 / 1;
    border-radius: 0.5rem;

    &.-big_cta {
      padding: 1.25rem;
    }

    &.-large {
      padding: 0.875rem;
    }

    &.-medium {
      padding: 0.5rem;
    }

    &.-small {
      padding: 0.25rem;
    }
  }

  &.-fab {
    padding: 1rem;
    border-radius: 1.625rem;
  }
}
</style>
