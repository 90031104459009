<template>
  <div>
    <ModalsSign
      :open="showModalsSign"
      :type="signFormType"
      @close="toggleModalsSign(false)"
      @submit="completeSignFormProcess"
    />

    <div
      :class="[
        'filters-mobile',
        {
          'filters-mobile--disabled': disabled,
        },
      ]"
    >
      <div class="container-buttons container-buttons--left">
        <Button
          :label="$t('filters.labels.filter')"
          class="order"
          icon="filter_alt"
          size="medium"
          subtype="icon-text"
          type-theme="secondary-light"
          @click="$emit('filter')"
        />

        <div v-click-outside="onClickOutside" class="filter popover-wrapper">
          <Button
            :label="$t('filters.labels.sort')"
            icon="sort"
            size="medium"
            subtype="icon-text"
            type-theme="secondary-light"
            @click="showFiltersDropdown = true"
          />

          <div v-if="showFiltersDropdown" :class="['popover', { open: showFiltersDropdown }]">
            <ul class="list">
              <li
                v-for="option in options"
                :key="option.key"
                class="list__option"
                @click="onSelectOption(option.key)"
              >
                <Icon v-show="selected === option.key" name="check" />
                <span class="description">
                  {{ option.value }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="container-buttons container-buttons--justify-right">
        <!-- Create alert action -->
        <Button
          :label="$t('pages.alerts.cta.add')"
          :state="canSaveAlert"
          icon="notification_add"
          size="medium"
          subtype="icon-text"
          type-theme="secondary-light"
          @click="$emit('newAlert')"
        />

        <!-- Save search action -->
        <Button
          :label="$t('pages.saved_searches.cta.add')"
          :icon="currentSearchSaved ? 'bookmark' : 'bookmark_border'"
          :state="canSaveSearch"
          :style="currentSearchSaved ? 'pointer-events: none;' : ''"
          size="medium"
          type-theme="secondary-light"
          @click="$emit('saveSearch')"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Dependencies
import { mapGetters } from 'vuex'

// Helpers & mixins
import checkViewportSize from '@/mixins/checkViewportSize'

// Components
import Icon from '@/components/Icon.vue'
import Button from '@/components/Button.vue'
import ModalsSign from '@/components/modals/Sign.vue'

export default {
  name: 'CatalogFiltersMobile',
  components: {
    Icon,
    Button,
    ModalsSign,
  },
  mixins: [checkViewportSize],
  props: {
    options: {
      type: Array,
      required: true,
    },
    selection: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    canSaveAlert: {
      type: String,
      required: false,
      default: 'enabled',
    },
    canSaveSearch: {
      type: String,
      required: false,
      default: 'enabled',
    },
    currentSearchSaved: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: this.selection,
      showFiltersDropdown: false,
      showModalsSign: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
    }),
  },
  methods: {
    onClickOutside() {
      if (this.showFiltersDropdown) {
        this.showFiltersDropdown = false
      }
    },
    onSelectOption(key) {
      if (this.disabled) return

      this.selected = key
      this.$emit('submit', key)
    },
    toggleModalsSign(value) {
      this.showModalsSign = value
    },
  },
}
</script>

<style lang="scss" scoped>
.filters-mobile {
  display: flex;
  z-index: 99;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  &--disabled {
    pointer-events: none !important;
  }

  .popover-wrapper {
    position: relative;

    .popover {
      position: absolute;
      z-index: 99;
      top: 2.5rem;
      width: auto;
      padding: 0.8rem;
      transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
      border-radius: 8px;
      background-color: $c-white;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);

      @include size-m-up {
        right: 0;
      }

      .list {
        margin: 0;
        padding: 0;
        list-style: none;

        &__option {
          position: relative;

          & + * {
            margin-top: 1rem;
          }

          .icon,
          .description {
            pointer-events: none;
          }

          .icon {
            position: absolute;
          }

          .description {
            margin-left: 2rem;
            white-space: nowrap;
          }
        }
      }
    }
  }

  @include size-s {
    gap: 1.25rem;

    .container-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1.25rem;

      &--left {
        width: calc(100% - 6.25rem);

        > .button {
          width: calc(100% - 3.75rem) !important;
        }
      }

      &--right {
        width: 6.25rem;
      }
    }

    .container-buttons--left .filter .button,
    .container-buttons--justify-right .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      margin: 0;
      padding: 0;

      :deep(.icon) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      :deep(span) {
        display: none;
      }
    }
  }
}
</style>
