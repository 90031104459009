<template>
  <transition name="modal">
    <div
      v-show="open"
      :class="[
        'modal-wrapper',
        modalName,
        {
          'modal-wrapper--centered-actions': centeredActions,
          'modal-wrapper--disabled': disabled,
          'modal-wrapper--full-screen--tablet': fullScreenOnTablet,
          'modal-wrapper--full-screen': fullScreenOnMobile,
          'modal-wrapper--inline': inline,
          'modal-wrapper--open': open,
          'modal-wrapper--self-contained': selfContained,
          'modal-wrapper--sticky-footer': stickyFooter,
        },
      ]"
      :data-cy="modalName"
      @click.prevent="$emit('close')"
    >
      <div class="modal-content-wrapper">
        <div
          data-cy="modal"
          :class="['modal', { 'modal--has-header': hasHeader }]"
          :style="`width: ${width}; height: ${height};`"
          @click.stop
        >
          <div class="modal__header">
            <div v-if="hasHeader">
              <span v-if="label" class="modal__header__label">
                {{ label }}
              </span>
              <span v-if="title" class="modal__header__title">
                {{ title }}
              </span>
              <span v-if="subtitle" class="modal__header__subtitle">
                {{ subtitle }}
              </span>
              <div v-if="$slots.header" class="modal__header__custom-content">
                <slot name="header" />
              </div>
            </div>

            <Button
              :disabled="disabled"
              class="modal__header__close"
              data-cy="modal-close-button"
              size="small"
              subtype="only-icon"
              type-theme="ghost-light"
              @click.prevent="() => onCancel()"
            >
              <Icon name="close" />
            </Button>
          </div>

          <div class="modal__main">
            <div class="modal__main__content">
              <slot />
            </div>

            <div
              v-if="$slots.actions || showAcceptButton || showCancelButton"
              class="modal__main__actions"
            >
              <Button
                v-if="showAcceptButton"
                :disabled="disabled"
                data-cy="modal-accept-button"
                type-theme="primary-light"
                @click.prevent="() => onAccept()"
              >
                {{ acceptButtonLabel ? acceptButtonLabel : $t('global.accept') }}
              </Button>

              <Button
                v-if="showCancelButton"
                :disabled="disabled"
                data-cy="modal-cancel-button"
                type-theme="secondary-light"
                @click.prevent="() => onCancel()"
              >
                {{ cancelButtonLabel ? cancelButtonLabel : $t('global.cancel') }}
              </Button>

              <slot name="actions" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// Components
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'

export default {
  components: {
    Button,
    Icon,
  },
  props: {
    centeredActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullScreenOnMobile: {
      type: Boolean,
      required: false,
      default: true,
    },
    fullScreenOnTablet: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    height: {
      type: String,
      required: false,
      default: 'auto',
    },
    width: {
      type: String,
      required: false,
      default: '36rem',
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    selfContained: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAcceptButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptButtonLabel: {
      type: String,
      required: false,
      default: '',
    },
    showCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    cancelButtonLabel: {
      type: String,
      required: false,
      default: '',
    },
    stickyFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    enablePreventBodyScroll: {
      type: Boolean,
      required: false,
      default: true,
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isTabletScreenSize: false,
    }
  },
  computed: {
    hasHeader() {
      return this.label || this.title || this.subtitle || this.$slots.header
    },
    modalName() {
      return `${this.name ? this.name.replaceAll(' ', '-').replaceAll('_', '-') : 'unnamed'}-modal`
    },
  },
  methods: {
    onEscKeyPressed(event) {
      if (event.key === 'Escape') {
        event.stopPropagation()
        this.$emit('close')
      }
    },
    onAccept() {
      this.$emit('close')
      this.$emit('close:accept')
    },
    onCancel() {
      this.$emit('close')
      this.$emit('close:cancel')
    },
    preventBodyScroll() {
      setTimeout(() => {
        if (this.enablePreventBodyScroll && document.body.style.position !== 'fixed') {
          document.body.style.right = 0
          document.body.style.left = 0
          document.body.style.bottom = 0
          document.body.style.top = `-${window.scrollY}px`
          document.body.style.position = 'fixed'
        }
      }, 100)
    },
    releaseBodyScroll() {
      if (document.body.style.position === 'fixed') {
        const scrollY = document.body.style.top
        document.body.style.position = ''
        document.body.style.top = ''
        document.body.style.right = ''
        document.body.style.left = ''
        document.body.style.bottom = ''
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      }
    },
    onOpen() {
      this.preventBodyScroll()
      document.addEventListener('keydown', this.onEscKeyPressed)
    },
    onClose() {
      this.releaseBodyScroll()
      document.removeEventListener('keydown', this.onEscKeyPressed)
    },
  },
  watch: {
    open: {
      handler() {
        if (this.open) {
          this.onOpen()
        } else {
          this.onClose()
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.open) {
      this.onOpen()
    }
  },
  destroyed() {
    this.onClose()
  },
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
  --padding: 1.25rem;

  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  @include size-xl-up {
    width: 100vw;
  }

  @include size-m-up {
    --padding: 1.5rem;
  }

  @include size-xl-up {
    --padding: 2rem;
  }

  @include size-xxl-up {
    --padding: 2.5rem;
  }

  &--centered-actions .modal__main__actions {
    justify-content: center;
  }

  @mixin modalWrapperSelfContainedStyle {
    .modal-content-wrapper {
      height: inherit;
      padding-bottom: unset;
      overflow-y: auto;

      .modal {
        height: auto;
        max-height: unset !important;
        margin-bottom: 4.5rem;
      }

      .modal__header {
        position: relative;
        top: unset;
      }
    }
  }

  @include size-s-up {
    &:not(.modal-wrapper--self-contained, .modal-wrapper--full-screen) {
      @include modalWrapperSelfContainedStyle;
    }
  }

  @include size-l-up {
    &:not(.modal-wrapper--self-contained).modal-wrapper--full-screen {
      @include modalWrapperSelfContainedStyle;
    }
  }

  @mixin fullScreenStyle {
    align-items: flex-start !important;
    max-width: 100% !important;
    height: 100vh !important;

    @supports (height: 100dvh) {
      height: 100dvh !important;
    }

    .modal-content-wrapper {
      padding: 0 !important;
    }

    .modal {
      width: 100% !important;
      height: 100vh !important;
      max-height: unset !important;
      border-radius: 0 !important;

      @supports (height: 100dvh) {
        height: 100dvh !important;
      }

      .modal__main {
        height: 100% !important;

        &__content {
          margin-bottom: auto !important;
        }
      }
    }
  }

  @include size-m {
    &.modal-wrapper--full-screen--tablet {
      @include fullScreenStyle;
    }
  }

  @include size-s {
    &.modal-wrapper--full-screen {
      @include fullScreenStyle;
    }
  }

  &--sticky-footer .modal__main__actions {
    position: sticky;
    z-index: 99;
    bottom: 0;
  }

  &--disabled {
    pointer-events: none !important;
    filter: grayscale(1) !important;
  }
}

.modal-content-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: calc(var(--navbar-height) + 5rem) 1rem 3.5rem;
  overflow-y: hidden;

  @include size-l-up {
    padding-right: 6.6875rem;
    padding-bottom: 4rem;
    padding-left: 6.6875rem;
  }

  @include size-xl-up {
    padding-right: 8.5rem;
    padding-left: 8.5rem;
  }

  @include size-xxl-up {
    padding-right: 13rem;
    padding-bottom: 4.5rem;
    padding-left: 13rem;
  }
}

.modal {
  --padding: 1.25rem;

  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 0.25rem;
  background-color: $c-white;

  @include size-s {
    max-width: unset;
  }

  &--has-header {
    .modal__header {
      display: flex;
      position: sticky;
      z-index: 99;
      top: 0;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      padding: 1.25rem var(--padding);
      border-bottom: 0.0625rem solid $c-neutral-100;

      > div {
        width: 100%;
      }
    }

    .modal__main__content {
      padding: 1.25rem var(--padding) 0;

      &:last-child {
        padding-bottom: var(--padding);
      }
    }
  }

  &:not(.modal--has-header) {
    .modal__main__content {
      padding: var(--padding);
    }

    .modal__header {
      display: flex;
      position: absolute !important;
      top: 1rem !important;
      right: 1rem !important;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  &__header {
    background-color: $c-white;

    span {
      display: block;
      transform: translateY(0.25rem);

      & + span,
      & + .modal__header__custom-content {
        margin-top: 0.5rem;
      }
    }

    &__label {
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.5rem;
    }

    &__title {
      font-size: 2.25rem;
      font-weight: 500;
      line-height: 2.5rem;

      @include size-l {
        font-size: 2rem;
      }

      @include size-s {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

    &__subtitle {
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 1.75rem;

      @include size-s {
        font-size: 1.125rem;
        line-height: 1.5rem;
      }
    }

    &__close {
      display: flex;
      z-index: 1;
      align-items: center !important;
      justify-content: center !important;
      width: 2rem !important;
      height: 2rem !important;
      color: var(--action-color);
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
  }

  &__main__content {
    width: 100%;
    padding-bottom: var(--padding) !important;

    > * {
      max-width: 100%;
    }

    :deep(p) {
      margin: 0;
      margin-block: 0;

      & + p {
        margin-top: 1.4em;
      }
    }
  }

  &__main__actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
    padding: var(--padding);
    border-top: 1px solid #f8f7fa;
    background-color: $c-white;

    @include size-xs {
      > *,
      > :deep(*) {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s ease;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}

// .modal-enter .modal,
// .modal-leave-active .modal {
//   transform: scale(1.1);
// }
</style>
