import { render, staticRenderFns } from "./Breadcrumb.vue?vue&type=template&id=64e5c986&scoped=true"
import script from "./Breadcrumb.vue?vue&type=script&lang=js"
export * from "./Breadcrumb.vue?vue&type=script&lang=js"
import style0 from "./Breadcrumb.vue?vue&type=style&index=0&id=64e5c986&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64e5c986",
  null
  
)

export default component.exports