<template>
  <div :class="['checkbox-wrapper', { 'skeleton-loading': skeletonLoading }]">
    <div class="checkbox-container">
      <div :class="`checkbox ${size}`">
        <input
          :id="`checkbox-${id}`"
          :aria-errormessage="error ? `input-${id}-error` : null"
          :aria-invalid="!!error"
          :aria-label="label"
          :disabled="disabled"
          :name="name"
          :required="required"
          class="checkbox-input"
          type="checkbox"
          v-bind="$attrs"
          @change="$emit('input', $event.target.checked)"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        />
        <span class="decoration" />
        <Icon :name="iconCheckbox" />
      </div>
      <label class="label" :for="`checkbox-${id}`">
        <span v-if="label" v-html="label" />
        <slot v-else />
      </label>
    </div>
    <div v-show="!!error" :id="`checkbox-${id}-error`" class="error size-14" role="alert">
      {{ error }}
    </div>
    <div v-show="!error && hint" :class="`hint size-14 ${size}`">{{ hint }}</div>
  </div>
</template>

<script>
import Icon from '@/components/Icon'

const SIZES = ['s', 'm']
const THEMES = ['line', 'check']
export default {
  name: 'Checkbox',
  components: { Icon },
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'input',
  },
  props: {
    name: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    hint: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      validator: (value) => SIZES.includes(value),
      default: 'm',
    },
    theme: {
      type: String,
      validator: (value) => THEMES.includes(value),
      default: 'check',
    },
    skeletonLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: [undefined, String],
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      id: null,
    }
  },
  computed: {
    iconCheckbox() {
      switch (this.theme) {
        case 'check':
          return 'check'
        case 'line':
          return 'minimize'
        default:
          return 'check'
      }
    },
  },
  mounted() {
    this.id = this._uid
  },
}
</script>

<style lang="scss" scoped>
.checkbox-container {
  --size: 1.5rem;

  display: flex;
  align-items: flex-start;
  margin-top: 1.5rem;
  cursor: pointer;

  &.s {
    --size: 1.25rem;
  }

  * {
    cursor: pointer;
  }

  .label {
    display: inline-flex;
    align-items: center;
    min-height: var(--size);
    margin-left: 0.6875rem;
  }

  .checkbox {
    position: relative;

    &.m {
      width: var(--size);
      min-width: var(--size);
      height: var(--size);
    }

    &.s {
      width: var(--size);
      min-width: var(--size);
      height: var(--size);
    }

    .decoration {
      display: inline-block;
      position: absolute;
      z-index: 0;
      left: 0;
      flex: 0 0 1.5rem;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border: solid 2px $c-silver-gray;
      border-radius: 4px;
      line-height: 1;
      pointer-events: none;
    }

    :deep(.icon) {
      position: relative;
      z-index: 1;
      color: transparent;
      pointer-events: none;
    }

    .checkbox-input {
      -webkit-appearance: none;
      position: absolute;
      appearance: none;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      border-radius: 3px;
      outline: none;
      background: none;

      &:checked {
        ~ .icon {
          color: $c-white;
        }

        ~ .decoration {
          border-color: $button-primary-hovered;
          background-color: $button-primary-hovered;
        }

        &:hover {
          ~ .decoration {
            border-color: $button-primary-hovered;
            background-color: $button-primary-hovered;
          }
        }

        &:active {
          ~ .decoration {
            border-color: $button-primary-pressed;
            background-color: $button-primary-pressed;
          }
        }

        &:disabled {
          ~ .decoration {
            border-color: $disabled-01;
            background-color: $disabled-01;
          }
        }
      }

      &:hover {
        ~ .decoration {
          border-color: $button-primary-hovered;
        }
      }

      &:active {
        ~ .decoration {
          border-color: $button-primary-pressed;
        }
      }

      &:disabled {
        ~ .decoration {
          border-color: $disabled-01;
        }
      }
    }
  }
}

.error {
  display: flex;
  padding-left: 2.25rem;
  color: $c-error;
}

.hint {
  display: flex;
  color: $text-secondary;

  &.m {
    padding-left: 2.25rem;
  }

  &.s {
    padding-left: 2rem;
  }
}
</style>
