<template>
  <i
    v-if="!custom"
    :name="name"
    :class="[
      'icon material-icons',
      {
        'material-icons--filled': filled,
      },
    ]"
    :style="size ? `font-size: ${size}` : ''"
  >
    {{ name }}
  </i>
  <svg-icon
    v-else
    :name="name"
    class="icon custom-icons"
    :style="size ? `font-size: ${size}` : ''"
  />
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
      // validator: (value) => ICONS.includes(value),
    },
    filled: {
      type: Boolean,
      default: false,
      required: false,
    },
    size: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      custom: ICONS.includes(this.name),
    }
  },
}

export const ICONS = [
  'astara_logo_reduced',
  'astara_logo',
  'astara_move_logo',
  'astara_store_big_logo',
  'check_title',
  'env-0',
  'env-b',
  'env-c',
  'env-eco',
  'facebook',
  'history_arrow',
  'icon-km0',
  'icon-vo',
  'instagram',
  'license-A',
  'license-A1',
  'license-A2',
  'license-AM',
  'linkedin',
  'sello_confianza_online',
  'spinner',
  'street_blocked',
  'twitter',
  'youtube',
  'street_blocked',
]
</script>

<style lang="scss" scoped>
.icon {
  color: currentColor;
  font-size: 1.5rem;

  &.custom-icons {
    position: relative;
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    transition: fill 0.3s;
    fill: currentColor;
  }

  &.material-icons {
    all: unset;
    display: inline-block;
    font-family: 'Material Icons Outlined', sans-serif;
    font-size: 1.5rem;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1;
    text-rendering: optimizeLegibility;
    text-transform: none;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    font-feature-settings: 'liga';

    &--filled {
      font-family: 'Material Icons', sans-serif;
    }
  }
}
</style>
