<template>
  <Icon name="spinner" class="spinner" />
</template>

<script>
import Icon from '@/components/Icon'
export default {
  name: 'Spinner',
  components: {
    Icon,
  },
}
</script>

<style lang="scss" scoped>
.spinner {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: spin 1.6s linear infinite;
}
</style>
