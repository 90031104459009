export default ({ app }, inject) => {
  // Methods
  const customSanitizer = (rawHtml, options = undefined) => {
    try {
      let transformedHtml = rawHtml

      // Transform <div> into <p>
      transformedHtml = rawHtml.replace(/<div/g, '<p').replace(/<\/div>/g, '</p>')

      // Remove empty paragraphs and those ones with the trix-content class
      const tempElement = document.createElement('div')
      tempElement.innerHTML = transformedHtml

      const paragraphsToRemove = tempElement.querySelectorAll('p')

      paragraphsToRemove.forEach((paragraph) => {
        if (paragraph.innerHTML.trim() === '') {
          paragraph.remove()
        } else if (paragraph.classList.contains('trix-content')) {
          paragraph.remove()
        } else if (paragraph.tagName.toLowerCase() === 'div') {
          const p = document.createElement('p')
          p.innerHTML = paragraph.innerHTML
          paragraph.replaceWith(p)
        }
      })

      if (
        tempElement.innerHTML.trim() &&
        !tempElement.innerHTML.startsWith('<p>') &&
        !tempElement.innerHTML.endsWith('</p>')
      ) {
        const p = document.createElement('p')
        p.innerHTML = tempElement.innerHTML
        tempElement.innerHTML = ''
        tempElement.appendChild(p)
      }

      if (options) {
        if (options?.obfuscateLinks) {
          const links = tempElement.querySelectorAll('a')

          links.forEach((link) => {
            const href = link.href

            if (!href.startsWith('mailto:') && !href.startsWith('tel:')) {
              const pseudoLink = document.createElement('div')
              pseudoLink.classList.add('pseudo-link', 'pseudo-link--passive')
              pseudoLink.setAttribute('data-href', href)

              const span = document.createElement('span')
              span.innerHTML = link.innerHTML
              pseudoLink.appendChild(span)

              link.parentNode.replaceChild(pseudoLink, link)
            }
          })
        }
      }

      return tempElement.innerHTML
    } catch {
      return rawHtml
    }
  }

  inject('customSanitizer', customSanitizer)
}
