<template>
  <div
    data-cy="catalog-card"
    :class="[
      'catalog-card',
      `catalog-card--${vehicleType.toLowerCase()}`,
      `catalog-card--${category.toLowerCase()}`,
      {
        'catalog-card--secondary': secondary,
        'catalog-card--out-of-stock': isOutOfStock,
        'catalog-card--sold-out': isSoldOut,
        'catalog-card--disabled': disabled || isSoldOut || isOutOfStock,
        'catalog-card--has-price-base': retailPrice,
      },
    ]"
  >
    <!-- Global link -->
    <NuxtLink
      v-if="vehicleUrl && !disabled && !isSoldOut && !isOutOfStock"
      class="catalog-card__link"
      :aria-label="`${$t(`vehicle.view_${isMotorbike ? 'moto' : 'car'}`)} ${[
        vehicleData?.makeName,
        modelNam,
        versionName,
      ].join(' ')}`"
      :aria-labelledby="`${$t(`vehicle.view_${isMotorbike ? 'moto' : 'car'}`)} ${[
        vehicleData?.makeName,
        modelNam,
        versionName,
      ].join(' ')}`"
      :to="vehicleUrl"
      @click.native="pushGtmEvent"
    />

    <!-- Header -->
    <header class="catalog-card__header">
      <div class="catalog-card__header__info">
        <!-- Vehicle info -->
        <div class="catalog-card__header__info__vehicle">
          <NuxtLink v-if="makeUrl" :to="makeUrl" class="make f-as-link">
            <span class="f-size-16">
              {{ vehicleData?.makeName }}
            </span>
          </NuxtLink>
          <span v-else class="make">
            {{ vehicleData?.makeName }}
          </span>

          <NuxtLink v-if="modelUrl" :to="modelUrl" class="model f-not-as-link">
            <span class="f-size-18 f-700">
              {{ modelName }}
            </span>
          </NuxtLink>
          <span v-else class="f-size-18 f-700 model">
            {{ modelName }}
          </span>

          <span v-if="versionName" class="f-size-14 f-400 version">
            {{ versionName }}
          </span>
        </div>

        <!-- Environmental labels -->
        <div class="catalog-card__header__info__badges">
          <div class="catalog-card__header__info__badges__group">
            <template v-if="isMotorbike && vehicleData?.drivingLicence">
              <Icon :name="`license-${vehicleData.drivingLicence}`" size="1.5rem" />
            </template>

            <template v-if="vehicleData.environmentalLabels">
              <Icon
                v-for="label in vehicleData.environmentalLabels"
                :key="label"
                :name="`env-${label}`"
                size="1.5rem"
              />
            </template>
          </div>
        </div>
      </div>

      <!-- Hero and flags -->
      <div class="catalog-card__header__hero">
        <ImageImgix :url="hero" :area="actualHeaderImageArea" :alt="heroAlt" :width="560" />

        <!-- Flags -->
        <PartialsUseCaseFlag v-if="useCaseFlag" :type="useCaseFlag" class="catalog-card__flag" />
      </div>
    </header>

    <!-- Main content -->
    <div class="catalog-card__main">
      <!-- Registration date and kilometers -->
      <span v-if="usedVehicleInfo" class="registration-and-kilometers f-size-14 f-500">
        {{ usedVehicleInfo }}
      </span>

      <!-- Favourite toggle-button -->
      <PartialsFavourite
        :favourite-markable-id="favouriteMarkableId"
        :favourite-markable-type="favouriteMarkableType"
        :options="vehicleData"
        class="favourite"
      />

      <!-- Vehicle pricing -->
      <div class="pricing">
        <span v-if="retailPrice" class="price price--base f-size-14 medium f-line-through">
          {{ retailPrice }}
        </span>
        <span class="f-size-18 f-700 f-highlighted price">{{ price }}</span>
      </div>

      <span class="f-size-14 installment-price f-highlighted" v-html="installmentPrice" />

      <!-- Featured vehicle specifications -->
      <div v-if="featuredSpecs" class="specs">
        <span v-for="spec in featuredSpecs" :key="spec" class="f-size-14 f-400">
          {{ spec }}
        </span>
      </div>
    </div>

    <!-- Actions -->
    <footer
      v-if="showActions"
      :class="[
        'catalog-card__footer',
        {
          'catalog-card__footer--wide-content': !showCta && campaignAction === 'contact',
        },
      ]"
    >
      <Button
        v-if="showCta"
        type-theme="secondary-light"
        size="small"
        :to="vehicleUrl"
        @click.native="pushGtmEvent"
      >
        <span>
          {{ $t(`vehicle.view_${isMotorbike ? 'moto' : 'car'}`) }}
        </span>
      </Button>
      <template v-else-if="campaignAction === 'contact'">
        <Button type-theme="secondary-light" size="small" @click="onOpenContactForm">
          <span>
            {{ $t('vehicle.contact') }}
          </span>
        </Button>
        <Button
          type-theme="secondary-light"
          size="small"
          :href="isSizeMUp ? undefined : `tel:${contactPhone}`"
          @click="() => (isSizeMUp ? onOpenContactForm() : $tracker.phoneClick(contactPhone))"
        >
          <Icon name="phone" size="1.25rem" />
          <span>
            {{ isSizeMUp ? $t('vehicle.we_call_you') : $t('vehicle.call') }}
          </span>
        </Button>
      </template>
    </footer>

    <ModalsContact
      :default-message="this.$t('modals.contact.default_message')"
      :financial-onboarding-url="financialOnboardingUrl"
      :open="showModalsContact"
      :vehicle-data="contactModalVehicleData"
      @formStatus="(status) => (modalsContactStatus = status)"
      @submit="(formData) => onSubmitContactForm(formData)"
      @close="toggleModalsContact(false)"
    />
  </div>
</template>

<script>
// Dependencies
import { mapGetters } from 'vuex'

// Helpers & mixins
import { toCurrency } from '@/helpers/currency'
import { getBaseUrl } from '@/helpers/urls'
import { normaliseData } from '@/helpers/vehicleDataNormalisation'
import checkViewportSize from '@/mixins/checkViewportSize'

// Components
import Button from '@/components/Button.vue'
import PartialsFavourite from '@/components/partials/Favourite.vue'
import Icon from '@/components/Icon.vue'
import ImageImgix from '@/components/ImageImgix.vue'
import ModalsContact from '@/components/modals/Contact.vue'
import PartialsUseCaseFlag from '@/components/partials/UseCaseFlag.vue'

export default {
  name: 'CatalogCard',
  components: {
    Button,
    PartialsFavourite,
    Icon,
    ImageImgix,
    ModalsContact,
    PartialsUseCaseFlag,
  },
  mixins: [checkViewportSize],
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: false,
      default: 'VN',
    },
    level: {
      type: String,
      required: false,
      default: undefined,
      validator(value) {
        return ['model', 'version', 'vehicle'].includes(value)
      },
    },
    eventName: {
      type: String,
      required: false,
      default: undefined,
    },
    listId: {
      type: String,
      required: false,
      default: undefined,
    },
    listName: {
      type: String,
      required: false,
      default: undefined,
    },
    showCta: {
      type: Boolean,
      required: false,
      default: false,
    },
    gtmData: {
      type: Object,
      required: false,
      default: undefined,
    },
    datalayerData: {
      type: Object,
      required: false,
      default: undefined,
    },
    headerImageArea: {
      type: String,
      required: false,
      default: '1030,578',
    },
    url: {
      type: String,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    secondary: {
      type: Boolean,
      required: false,
      default: false,
    },
    hiddenFlags: {
      type: [Array, undefined],
      required: false,
      default: undefined,
    },
    hideFlags: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showModalsContact: false,
      modalsContactStatus: false,
      contactPhone: process.env.contactPhone,
    }
  },
  computed: {
    ...mapGetters({
      languageIso: 'getLanguageIso',
    }),
    financialOnboardingUrl() {
      return `${getBaseUrl()}${this.vehicleUrl}`
    },
    lang() {
      return this.languageIso || 'es'
    },
    actualLevel() {
      if (this.level) {
        return this.level
      } else {
        return this.isMotorbike ? 'version' : this.isVn ? 'model' : 'vehicle'
      }
    },
    vehicleData() {
      return normaliseData(this.vehicle, this.category, this.actualLevel)
    },
    vehicleType() {
      try {
        let type = this.vehicleData?.vehicleType

        if (!type) {
          type = ['vn', 'km0', 'vo', 'move'].includes(this.category.toLowerCase())
            ? 'car'
            : 'motorbike'
        }

        return type
      } catch {
        return 'car'
      }
    },
    isMove() {
      return this.vehicleType.toLowerCase() === 'car' && this.category.toLowerCase() === 'move'
    },
    isVn() {
      return this.vehicleType.toLowerCase() === 'car' && this.category.toLowerCase() === 'vn'
    },
    isVo() {
      return this.vehicleType.toLowerCase() === 'car' && this.category.toLowerCase() === 'vo'
    },
    isKm0() {
      return this.vehicleType.toLowerCase() === 'car' && this.category.toLowerCase() === 'km0'
    },
    isMotorbike() {
      return ['moto', 'motorbike', 'motorcycle'].includes(this.vehicleType.toLowerCase())
    },
    isSoldOut() {
      return (this.isKm0 || this.isVo || this.isMove) && this.vehicleData?.publishableUnits === 0
    },
    isOutOfStock() {
      return (this.isVn || this.isMotorbike) && this.vehicleData?.publishableUnits === 0
    },
    isPromotedForBlackFriday() {
      return this.$isPromotedForBlackFriday(this.vehicleData.useCases)
    },
    isPromoted() {
      return !this.isVn && this.$isPromoted(this.vehicleData.useCases)
    },
    vehicleUrl() {
      try {
        if (this.url) {
          return this.url
        } else {
          return this.localePath(this.vehicleData.url)
        }
      } catch {
        return undefined
      }
    },
    hero() {
      try {
        let preferredHero
        if (this.isMotorbike) {
          preferredHero = this.vehicleData?.vehicleConfigHero
        }

        return preferredHero || this.vehicleData?.hero || this.vehicleData?.heroBackUp
      } catch {
        return undefined
      }
    },
    actualHeaderImageArea() {
      if (this.headerImageArea) return this.headerImageArea

      try {
        switch (this.category.toLowerCase()) {
          case 'km0':
          case 'vo':
          case 'move':
            return '1030,578'
          default:
            return '1240,698'
        }
      } catch {
        return undefined
      }
    },
    heroAlt() {
      try {
        return [
          this.$t(`global.vehicle_types.${this.vehicleType?.toLowerCase()}`)[0],
          this.vehicleData?.makeName,
          this.vehicleData?.modelName,
          this.vehicleData?.versionName,
        ].join(' ')
      } catch {
        return undefined
      }
    },
    useCaseFlag() {
      if (this.hideFlags) return undefined

      const confirmUseCase = (valid, name) => {
        return valid && !this.hiddenFlags?.includes(name)
      }

      const checkUseCase = (name) => {
        return confirmUseCase(this.vehicleData.useCases?.includes(name), name)
      }

      if (confirmUseCase(this.isSoldOut, 'sold')) {
        return 'sold'
      } else if (confirmUseCase(this.isOutOfStock, 'out_of_stock')) {
        return 'out_of_stock'
      } else if (confirmUseCase(this.isPromotedForBlackFriday, 'black_friday')) {
        return 'black_friday'
      } else if (checkUseCase('summer_days')) {
        return 'summer_days'
      } else if (
        confirmUseCase(this.$isPromotedForShowroomPrive(this.vehicleData.useCases), 'showroomprive')
      ) {
        return 'showroomprive'
      } else if (confirmUseCase(this.isPromoted, 'on_offer')) {
        return 'on_offer'
      } else {
        return undefined
      }
    },
    makeUrl() {
      try {
        return this.localePath({
          name: `${this.vehicleType.toLowerCase()}-make`,
          params: {
            make: this.vehicleData.makeSlug,
          },
        })
      } catch {
        return undefined
      }
    },
    modelName() {
      if (!this.vehicleData?.modelName) return undefined

      if (this.isMotorbike) {
        if (!this.vehicleData?.versionName) return undefined

        return [this.vehicleData?.modelName, this.vehicleData?.versionName].join(' ')
      } else {
        return this.vehicleData?.modelName
      }
    },
    modelUrl() {
      // try {
      //   return this.localePath({
      //     name: `${this.vehicleType.toLowerCase()}-make-model`,
      //     params: {
      //       make: this.vehicleData.makeSlug,
      //       model: this.vehicleData.modelSlug.replace(/-\d{4}(?=\s*$)/, ''),
      //     },
      //   })
      // } catch {
      //   return undefined
      // }

      // TODO: enable when Product team defines how we want to show this link
      return undefined
    },
    versionName() {
      if (this.isMotorbike || !this.vehicleData?.versionName) return undefined

      return this.vehicleData?.versionName
    },
    usedVehicleInfo() {
      try {
        if (this.isVn || this.isMotorbike) return undefined

        const string = []

        let year, month

        const registrationDate = this.vehicleData.registrationDate

        if (registrationDate) {
          const registrationDateSplitted = registrationDate.split('-')
          year = registrationDateSplitted[0]
          month = parseInt(registrationDateSplitted[1]) - 1
        }

        if (this.isVo || this.isMove) {
          if (year) string.push(year)

          const kms = this.vehicleData?.kilometres
          if (kms) string.push(`${kms} km`)
        } else {
          const monthName = month ? this.$t(`global.months_number.${month}`) : undefined

          const substring = []
          if (monthName) substring.push(monthName)
          if (year) substring.push(year)

          string[0] = substring.join(' ')
        }

        return string.join(', ')
      } catch {
        return undefined
      }
    },
    installmentPrice() {
      try {
        const value = this.vehicleData.pricing.financing[0].installment_price

        if (!value) return '—'

        const amount = toCurrency({
          value: this.vehicleData.pricing.financing[0].installment_price,
          lang: this.lang,
        })

        return this.$t('global.from_price_per_month_html', { amount })
      } catch {
        return undefined
      }
    },
    financingPriceValue() {
      try {
        return this.vehicleData.pricing.financing[0].financing_price
      } catch {
        return undefined
      }
    },
    priceWithDiscountValue() {
      try {
        if (!this.vehicleData.discounts?.length > 0) return undefined

        const discount = this.vehicleData.discounts[0]
        const endDate = new Date(discount.endDate)
        const startDate = new Date(discount.startDate)
        const today = new Date()

        if (today > startDate && today < endDate) {
          const discountedPrice = this.vehicleData.pricing?.discounted_price

          if (discount.typeId === 'fixed_value') {
            return discountedPrice - discount.amountCents
          } else {
            return discountedPrice * discount.percentage
          }
        } else {
          return undefined
        }
      } catch {
        return undefined
      }
    },
    retailPrice() {
      try {
        const value = this.vehicleData.pricing?.retail_price || 0
        const referencePrice = this.priceWithDiscountValue || this.financingPriceValue || 0

        if (value <= referencePrice) {
          return undefined
        }

        return toCurrency({
          value,
          lang: this.lang,
        })
      } catch {
        return undefined
      }
    },
    price() {
      try {
        const value = this.priceWithDiscountValue || this.financingPriceValue

        if (!value) {
          return undefined
        }

        return toCurrency({
          value,
          lang: this.lang,
        })
      } catch {
        return undefined
      }
    },
    actualSpecs() {
      switch (this.actualLevel) {
        case 'model':
          return this.vehicleData.modelSpecs
        case 'version':
          return this.vehicleData.versionSpecs
        default:
          return this.vehicleData.vehicleSpecs
      }
    },
    featuredSpecs() {
      try {
        if (this.isVn) return undefined

        if (this.isMotorbike) {
          return [
            ...(this.vehicleData.bodywork
              ? [this.$t(`filters.labels.${this.vehicleData.bodywork}`)]
              : []),
            ...(this.actualSpecs.engines[0]?.cubicCapacity
              ? [this.actualSpecs.engines[0].cubicCapacity + 'cc']
              : []),
            ...(this.actualSpecs.engines[0]?.batteryMaxPower
              ? [this.actualSpecs.engines[0].batteryMaxPower + 'kW']
              : []),
            ...(this.actualSpecs.engines[0]?.maxHorsepower
              ? [this.actualSpecs.engines[0].maxHorsepower + 'CV']
              : []),
          ]
        } else {
          switch (this.category.toLowerCase()) {
            case 'move':
            case 'km0':
            case 'vo':
              return [
                ...(this.actualSpecs.powertrainType === 'combustion'
                  ? [this.$t(`filters.labels.${this.actualSpecs.engines[0].fuelType}`)]
                  : [this.$t(`filters.labels.${this.actualSpecs.powertrainType}`)]),
                ...(this.actualSpecs.transmissionType
                  ? [this.$t(`filters.labels.${this.actualSpecs.transmissionType}`)]
                  : []),
                ...(this.actualSpecs.totalMaxHorsepower
                  ? [this.actualSpecs.totalMaxHorsepower + ' CV']
                  : []),
              ]
            default:
              return undefined
          }
        }
      } catch {
        return undefined
      }
    },
    favouriteMarkableId() {
      try {
        if (this.actualLevel) {
          switch (this.actualLevel) {
            case 'version':
              return this.vehicleData.versionSlug
            case 'vehicle':
              return this.vehicleData.manufacturerRef
            case 'model':
            default:
              return this.vehicleData.modelSlug
          }
        } else {
          switch (this.category.toLowerCase()) {
            case 'move':
            case 'vo':
            case 'km0':
              return this.vehicleData.manufacturerRef
            default:
              return this.vehicleUrl.split('/').slice(-1)[0]
          }
        }
      } catch {
        return undefined
      }
    },
    favouriteMarkableType() {
      try {
        return this.actualLevel.charAt(0).toUpperCase() + this.actualLevel.slice(1)
      } catch {
        return undefined
      }
    },
    campaignAction() {
      return this.$store.getters['campaign/getAction']
    },
    showActions() {
      return this.showCta || this.campaignAction === 'contact'
    },
    contactModalVehicleData() {
      try {
        return {
          vehicle_type: this.vehicleType,
          vehicle_category: this.category,
          vehicle_photo_url: this.hero,
          price: this.vehicleData?.price || null,
          category: this.category,
          make: this.vehicleData?.makeName || null,
          model: this.vehicleData?.modelName || null,
          version: this.vehicleData?.versionName || null,
          bodywork: this.vehicleData?.bodywork || null,
          engine_type: this.actualSpecs?.powertrainType || null,
          fuel_type: this.actualSpecs?.engines[0]?.fuelType || null,
          ...this.vehicleData,
        }
      } catch {
        return {}
      }
    },
  },
  methods: {
    pushGtmEvent() {
      if (this.datalayerData && this.gtmData) {
        this.gtmEvent()
      } else if (this.vehicle) {
        this.autoGtmEvent()
      }
    },
    gtmEvent() {
      if (process?.browser && process?.env?.gtm_enabled) {
        this.$gtm.push(this.gtmData)
      }

      if (this.datalayerData) {
        this.$tracker.selectItem(
          this.datalayerData.list_id,
          this.datalayerData.list_name,
          this.datalayerData.item,
          {
            vehicleType: this.datalayerData.vehicleType,
            category: this.datalayerData.category,
          },
        )
      }
    },
    autoGtmEvent() {
      if (process?.browser && process?.env?.gtm_enabled) {
        // SEO
        let data
        switch (this.category.toLowerCase()) {
          case 'vn':
            data = {
              event: this.eventName,
              make: this.vehicleData?.makeName,
              model: this.vehicleData?.modelName,
            }
            break
          case 'km0':
          case 'vo':
          case 'move':
            data = {
              event: this.eventName,
              make: this.vehicleData?.makeName,
              model: this.vehicleData?.modelName,
              version: this.vehicleData?.versionName,
              config: this.vehicleData?.vehicleConfigSlug,
              vehicle_uid: this.vehicleData?.uid,
            }
            break
          case 'motorbike':
          case 'moto':
          case 'motorcycle':
          default:
            data = {
              event: this.eventName,
              make: this.vehicleData?.makeName,
              model: this.vehicleData?.modelName,
              version: this.vehicleData?.versionName,
            }
            break
        }

        this.$gtm.push(data)

        this.$tracker.selectItem(
          this.listId || this.listName?.replaceAll(' ', '-'),
          this.listName,
          this.vehicle,
          {
            category: this.isVn || this.isMotorbike ? 'new' : 'used',
            vehicleType: this.vehicleType,
          },
        )
      }
    },
    onOpenContactForm() {
      this.showModalsContact = true

      // SEO
      if (process?.browser && process?.env?.gtm_enabled) {
        this.$gtm.push({
          event: `contact_${this.actualLevel}_card`,
          make: this.vehicleData?.makeName,
          model: this.vehicleData?.modelName,
          version: this.vehicleData?.versionName,
          source: this.$store.getters['campaign/getSource'],
          medium: this.$store.getters['campaign/getMedium'],
          campaign: this.$store.getters['campaign/getCampaign'],
        })
      }

      this.$tracker.contactFormClick('page')
    },
    onSubmitContactForm(formData) {
      if (this.modalsContactStatus) {
        // SEO
        if (process?.browser && process?.env?.gtm_enabled) {
          this.$gtm.push({
            event: `confirmation_contact_${this.actualLevel}_card`,
            make: this.vehicleData?.makeName,
            model: this.vehicleData?.modelName,
            version: this.vehicleData?.versionName,
            source: this.$store.getters['campaign/getSource'],
            medium: this.$store.getters['campaign/getMedium'],
            campaign: this.$store.getters['campaign/getCampaign'],
          })
        }

        this.$tracker.contactFormCompleted({
          ...formData,
          origin: formData.origin,
          vehicle_type: this.vehicleType.toLowerCase(),
          product_condition: ['vo', 'km0', 'move'].includes(this.category.toLowerCase())
            ? 'used'
            : 'new',
          status: this.modalsContactStatus,
        })
      }
    },
    toggleModalsContact(value) {
      this.showModalsContact = value
    },
  },
}
</script>

<style lang="scss" scoped>
.catalog-card {
  --pricing-row-height: 1.5rem;

  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.25rem;
  background-color: $c-white;
  box-shadow: $c-elevation-1;

  &--has-price-base {
    --pricing-row-height: 2.75rem;
  }

  a {
    position: relative;
    z-index: 2;
  }

  .favourite {
    position: relative;
    z-index: 3;

    .sign-form {
      z-index: 9;
    }
  }

  :deep(.before-now-price) {
    display: inline-flex;
    gap: 0.2em;
  }

  &--secondary {
    background-color: $c-neutral-100;
  }

  &--disabled :deep(img) {
    opacity: 0.25;
  }

  &:not(.catalog-card--disabled) {
    @include size-l-up {
      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: $c-elevation-2;
      }
    }
  }

  &__link {
    position: absolute !important;
    z-index: 1 !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__info {
      display: grid;
      grid-template-columns: minmax(0, 100%) 1fr;
      gap: 0.5rem;
      width: 100%;
      padding: 1rem;

      &__vehicle {
        display: flex;
        grid-column: 1;
        flex-direction: column;
        gap: 0.25rem;

        span {
          display: block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .make {
          color: $text-secondary !important;
          text-transform: uppercase;
        }

        .catalog-card__flag {
          margin: 0 0 0 -1rem;
        }
      }

      &__badges {
        display: flex;
        z-index: 1;
        grid-column: 2;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: flex-end;
        gap: 1rem;

        &__group {
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-start;
          justify-content: flex-end;
          gap: 0.25rem;
        }

        .icon {
          margin: 0 -0.25rem;
        }
      }
    }

    &__hero {
      position: relative;
      width: 100%;
      margin: 0;
      aspect-ratio: 16 / 9;

      :deep(img) {
        display: block;
        width: 100%;
        object-fit: contain;
        object-position: center;
      }

      .catalog-card__flag {
        position: absolute !important;
        z-index: 1;
        top: 0.5rem;
        left: 0;
      }
    }
  }

  &__main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr var(--pricing-row-height) 1.25rem;
    align-content: end;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    padding: 0.5rem 1rem 1rem;
    gap: 0.25rem 0.5rem;

    span {
      white-space: nowrap;
    }

    .registration-and-kilometers {
      grid-column: 1;
      grid-row: 1;
      align-self: start;
      padding-bottom: 1rem;
    }

    .favourite {
      grid-column: 2;
      grid-row: 1;
      align-self: start;
      justify-self: end;
      padding-bottom: 1rem;
    }

    .installment-price,
    .pricing {
      flex: 1 1 0;
      align-self: end;
    }

    .pricing {
      display: inline-flex;
      grid-column: 1;
      grid-row: 2;
      flex-direction: column;
      font-weight: 500;
    }

    .installment-price {
      grid-column: 1;
      grid-row: 3;
    }

    .specs {
      grid-column: 2;
      grid-row: 2 / 4;
      align-self: end;
      text-align: right;
      justify-self: end;

      > span {
        display: block;
      }
    }

    .black-friday {
      display: flex;
      flex-direction: row;
      gap: 0.125rem;
    }

    .black-friday-price {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      * {
        font-size: 0.875rem;
      }
    }
  }

  &__footer {
    z-index: 2;
    padding: 0 1rem 1rem;

    &--wide-content {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      > * {
        flex: 1 1 auto;
      }
    }
  }

  &--car.catalog-card--vn {
    .catalog-card__main {
      grid-template-rows: var(--pricing-row-height) 1.25rem;

      .pricing {
        grid-row: 1;
      }

      .installment-price {
        grid-row: 2;
      }
    }
  }

  .catalog-card-home__header__hero img {
    mix-blend-mode: multiply;
  }
}
</style>
