export const state = () => ({
  options: {},
})

export const getters = {
  getOptions(state) {
    return state.options
  },
}

export const mutations = {
  setOptions(state, options) {
    state.options = options

    if (process.client) {
      const storedState = sessionStorage.getItem('astara_store')

      let currentState
      if (storedState) {
        currentState = JSON.parse(storedState)
      }

      sessionStorage.setItem(
        'astara_store',
        JSON.stringify({
          ...currentState,
          searchOptions: options,
        }),
      )
    }
  },
}

export const actions = {
  initializeStore({ state, commit }) {
    if (process.client) {
      const storedState = sessionStorage.getItem('astara_store')
      let currentState

      if (storedState) {
        currentState = JSON.parse(storedState)
      }

      if (!Object.keys(state?.options || {}).length) {
        if (currentState && currentState.searchOptions) {
          commit('setOptions', currentState.searchOptions)
        }
      } else {
        const newState = {
          ...currentState,
          searchOptions: state.options,
        }
        sessionStorage.setItem('astara_store', JSON.stringify(newState))
      }
    }
  },

  saveOptions({ commit }, options) {
    commit('setOptions', options)
  },

  clearOptions({ commit }) {
    commit('setOptions', {})
  },
}
