const DEPLOY_ENV = process.env.DEPLOY_ENV || 'development'
const sentryDSN = 'https://f2e06be11e71448a8b2dae94f0f60335@o264740.ingest.us.sentry.io/5770088'
const countriesStaging = {
  es: {
    iso: 'es',
    name: 'España',
    apiKey: process.env.COMPANY_APIKEY_SPAIN_STAGING,
    defaultLanguage: 'es',
    availableLanguages: ['es_ES', 'es_EN'],
  },
}
const countriesProduction = {
  es: {
    iso: 'es',
    name: 'España',
    apiKey: process.env.COMPANY_APIKEY_SPAIN,
    defaultLanguage: 'es',
    availableLanguages: ['es_ES'],
  },
}
const oneTrustIdStaging = '2ef2645f-a086-48c3-b066-e8885bc296e3-test'
const oneTrustIdProduction = '2ef2645f-a086-48c3-b066-e8885bc296e3'

export const configurations = {
  development: {
    host: 'http://localhost:8080',
    apiUrl: 'http://localhost:3000/api',
    sentryDSN: false,
    urlImgix: 'https://astarastore-staging.imgix.net',
    nemuruCDN: 'https://cdn.nemuru.com/assets/stg/nemuru.stg.js',
    nemuruClientId: process.env.NEMURU_CLIENT_ID_SPAIN_STAGING,
    gtm_enabled: true,
    gtm_id: '',
    countries: countriesStaging,
    onetrust: {
      enabled: false,
      id: oneTrustIdStaging,
    },
    uidMoveDealer: process.env.MOVE_DEALER_UID_STAGING,
  },
  staging: {
    host: 'https://staging.astarastore.com',
    apiUrl: 'https://thunder-staging.astarastore.com/api',
    sentryDSN,
    urlImgix: 'https://astarastore-staging.imgix.net',
    nemuruCDN: 'https://cdn.nemuru.com/assets/stg/nemuru.stg.js',
    nemuruClientId: process.env.NEMURU_CLIENT_ID_SPAIN_STAGING,
    gtm_enabled: true,
    gtm_id: 'GTM-W2J48WBB',
    countries: countriesStaging,
    onetrust: {
      enabled: false,
      id: oneTrustIdStaging,
    },
    uidMoveDealer: process.env.MOVE_DEALER_UID_STAGING,
  },
  production: {
    host: 'https://astarastore.com',
    apiUrl: 'https://thunder.astarastore.com/api',
    sentryDSN,
    urlImgix: 'https://astarastore.imgix.net',
    nemuruCDN: 'https://cdn.nemuru.com/assets/prod/nemuru.prod.js',
    nemuruClientId: process.env.NEMURU_CLIENT_ID_SPAIN,
    gtm_enabled: true,
    gtm_id: 'GTM-MNTVTCF',
    countries: countriesProduction,
    onetrust: {
      enabled: true,
      id: oneTrustIdProduction,
    },
    uidMoveDealer: process.env.MOVE_DEALER_UID,
  },
}

export const config = configurations[DEPLOY_ENV]
export const env = {
  name: process.env.DEPLOY_ENV,
  isDevelopment: process.env.DEPLOY_ENV === 'development',
  isTest: process.env.NODE_ENV === 'test',
  isProduction: process.env.DEPLOY_ENV === 'production',
  gtm_enabled: configurations[DEPLOY_ENV].gtm_enabled,
  urlImgix: configurations[DEPLOY_ENV].urlImgix,
  nemuruClientId: configurations[DEPLOY_ENV].nemuruClientId,
  nemuruCDN: configurations[DEPLOY_ENV].nemuruCDN,
  validateAddressAPI: process.env.GOOGLE_VALIDATE_ADDRESS_APIKEY,
  uidMoveDealer: configurations[DEPLOY_ENV].uidMoveDealer,
  defaultFinancialEntity: 'Nemuru',
  contactPhone: '+34 910 552 477',
  contactEmail: 'help@astarastore.com',
}
