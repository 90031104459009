<template>
  <div
    :class="[
      'breadcrumb size-14',
      {
        'breadcrumb--secondary': secondary,
        'breadcrumb--hide-on-mobile': hideOnMobile,
      },
    ]"
    data-cy="breadcrumb"
  >
    <div>
      <NuxtLink
        :to="homeLink ? localePath(homeLink) : localePath('index')"
        class="breadcrumb__link"
      >
        <img v-if="homeIcon" loading="lazy" src="/images/home-icon.webp" alt="Homepage icon" />
        {{ !homeIcon ? 'Home' : '' }}
      </NuxtLink>
    </div>
    <div v-if="breadcrumbLinks?.length" class="breadcrumb-links">
      <div v-for="link in breadcrumbLinks" :key="link.name">
        <Icon name="navigate_next" class="breadcrumb__icon" size="1rem" />
        <NuxtLink
          v-if="link?.url"
          :to="localePath(link.url)"
          data-cy="breadcrumb-link"
          class="breadcrumb__link breadcrumb__link--f-as-link"
        >
          {{ link.name }}
        </NuxtLink>
        <span v-else class="breadcrumb__link">
          {{ link.name }}
        </span>
      </div>
    </div>
    <div v-if="currentPage">
      <Icon name="navigate_next" class="breadcrumb__icon" size="1rem" />
      <span v-if="currentPage" class="breadcrumb__link breadcrumb__link--current">
        {{ currentPage }}
      </span>
    </div>
  </div>
</template>

<script>
// Componentes
import Icon from '@/components/Icon'

export default {
  name: 'Breadcrumb',
  components: {
    Icon,
  },
  props: {
    hideOnMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    breadcrumbLinks: {
      type: Array,
      required: false,
      default: null,
    },
    currentPage: {
      type: String,
      required: false,
      default: null,
    },
    homeIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    secondary: {
      type: Boolean,
      required: false,
      default: false,
    },
    homeLink: {
      type: String,
      required: false,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  --crumb-color: #7e7b89;
  --current-crumb-color: #1e1932;

  &--secondary {
    --crumb-color: #b1a8d4;
    --current-crumb-color: #f7f6fb;
  }

  display: flex;

  &--hide-on-mobile {
    @include size-s {
      display: none;
    }
  }

  @include size-s {
    display: none;
  }

  &__icon {
    width: 1rem;
    margin: 0 0.25rem;
    transform: rotate(180deg);
    transform: scale(-1);
    fill: $c-neutral-800;
    fill: var(--crumb-color);
  }

  &__link {
    display: inline-block;
    color: var(--crumb-color);
    font-size: $font-size-sm;
    font-weight: bold;
    text-decoration: none;

    &--f-as-link {
      &:not(.breadcrumb__link--current):hover,
      &:not(.breadcrumb__link--current):active {
        color: var(--crumb-color);
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &--current {
      color: var(--current-crumb-color);
    }
  }
}

div {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
</style>
