import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f6d781f6 = () => interopDefault(import('../pages/astara-selection/index.vue' /* webpackChunkName: "pages/astara-selection/index" */))
const _64f02862 = () => interopDefault(import('../pages/black-friday.vue' /* webpackChunkName: "pages/black-friday" */))
const _7f6c2791 = () => interopDefault(import('../pages/saved-searches.vue' /* webpackChunkName: "pages/saved-searches" */))
const _4bc31f28 = () => interopDefault(import('../pages/consent-commercial-communications.vue' /* webpackChunkName: "pages/consent-commercial-communications" */))
const _475d9f3b = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _29c1fd68 = () => interopDefault(import('../pages/buying-power/index.vue' /* webpackChunkName: "pages/buying-power/index" */))
const _049e3fad = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _212eecda = () => interopDefault(import('../pages/etiqueta-cero.vue' /* webpackChunkName: "pages/etiqueta-cero" */))
const _ff8b51c2 = () => interopDefault(import('../pages/favourites.vue' /* webpackChunkName: "pages/favourites" */))
const _103670f2 = () => interopDefault(import('../pages/financing.vue' /* webpackChunkName: "pages/financing" */))
const _0c370d71 = () => interopDefault(import('../pages/warranty.vue' /* webpackChunkName: "pages/warranty" */))
const _1076f653 = () => interopDefault(import('../pages/sign-in/index.vue' /* webpackChunkName: "pages/sign-in/index" */))
const _632e79a4 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _0d1dc315 = () => interopDefault(import('../pages/motorbikes/index.vue' /* webpackChunkName: "pages/motorbikes/index" */))
const _5b44facb = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _001a5236 = () => interopDefault(import('../pages/cookies-policy.vue' /* webpackChunkName: "pages/cookies-policy" */))
const _7ade5f68 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _79f7e292 = () => interopDefault(import('../pages/faqs.vue' /* webpackChunkName: "pages/faqs" */))
const _6c97b936 = () => interopDefault(import('../pages/password-recovery/index.vue' /* webpackChunkName: "pages/password-recovery/index" */))
const _d2d7c76e = () => interopDefault(import('../pages/sign-up/index.vue' /* webpackChunkName: "pages/sign-up/index" */))
const _51c1863e = () => interopDefault(import('../pages/salon-vo.vue' /* webpackChunkName: "pages/salon-vo" */))
const _134a19fa = () => interopDefault(import('../pages/select-country.vue' /* webpackChunkName: "pages/select-country" */))
const _6b50fdb8 = () => interopDefault(import('../pages/slice-simulator.vue' /* webpackChunkName: "pages/slice-simulator" */))
const _36333630 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _bd3a84ba = () => interopDefault(import('../pages/trade-in.vue' /* webpackChunkName: "pages/trade-in" */))
const _bf6f60c8 = () => interopDefault(import('../pages/use-terms.vue' /* webpackChunkName: "pages/use-terms" */))
const _c6898666 = () => interopDefault(import('../pages/vehiculos-eco.vue' /* webpackChunkName: "pages/vehiculos-eco" */))
const _6c32995d = () => interopDefault(import('../pages/alerts/new.vue' /* webpackChunkName: "pages/alerts/new" */))
const _9d565882 = () => interopDefault(import('../pages/astara-selection/cars/index.vue' /* webpackChunkName: "pages/astara-selection/cars/index" */))
const _4feec7d6 = () => interopDefault(import('../pages/cars/km0/index.vue' /* webpackChunkName: "pages/cars/km0/index" */))
const _ffcfc9f4 = () => interopDefault(import('../pages/cars/vn/index.vue' /* webpackChunkName: "pages/cars/vn/index" */))
const _14fccdc7 = () => interopDefault(import('../pages/cars/vo/index.vue' /* webpackChunkName: "pages/cars/vo/index" */))
const _1fee3ee2 = () => interopDefault(import('../pages/customer/settings.vue' /* webpackChunkName: "pages/customer/settings" */))
const _32a9be06 = () => interopDefault(import('../pages/customer/details.vue' /* webpackChunkName: "pages/customer/details" */))
const _13b42114 = () => interopDefault(import('../pages/customer/orders/index.vue' /* webpackChunkName: "pages/customer/orders/index" */))
const _6f4cb1f0 = () => interopDefault(import('../pages/buying-power/results.vue' /* webpackChunkName: "pages/buying-power/results" */))
const _230193ef = () => interopDefault(import('../pages/sign-in/otp/index.vue' /* webpackChunkName: "pages/sign-in/otp/index" */))
const _7b7a3dad = () => interopDefault(import('../pages/campaigns/showroomprive.vue' /* webpackChunkName: "pages/campaigns/showroomprive" */))
const _1539f338 = () => interopDefault(import('../pages/campaigns/summer-days.vue' /* webpackChunkName: "pages/campaigns/summer-days" */))
const _46737f47 = () => interopDefault(import('../pages/password-recovery/edit.vue' /* webpackChunkName: "pages/password-recovery/edit" */))
const _31447d46 = () => interopDefault(import('../pages/password-recovery/update.vue' /* webpackChunkName: "pages/password-recovery/update" */))
const _63a3b795 = () => interopDefault(import('../pages/sign-up/create.vue' /* webpackChunkName: "pages/sign-up/create" */))
const _01a51dfc = () => interopDefault(import('../pages/sign-in/otp/update.vue' /* webpackChunkName: "pages/sign-in/otp/update" */))
const _52496382 = () => interopDefault(import('../pages/sign-in/otp/expired.vue' /* webpackChunkName: "pages/sign-in/otp/expired" */))
const _6e5c9806 = () => interopDefault(import('../pages/customer/orders/_order.vue' /* webpackChunkName: "pages/customer/orders/_order" */))
const _2314b446 = () => interopDefault(import('../pages/car/vn/_make/_model/index.vue' /* webpackChunkName: "pages/car/vn/_make/_model/index" */))
const _5b6d8518 = () => interopDefault(import('../pages/car/vn/_make/_model/_version/index.vue' /* webpackChunkName: "pages/car/vn/_make/_model/_version/index" */))
const _41a8453a = () => interopDefault(import('../pages/astara-selection/car/_make/_model/_version/_config/_vehicle_uid/index.vue' /* webpackChunkName: "pages/astara-selection/car/_make/_model/_version/_config/_vehicle_uid/index" */))
const _3559b01a = () => interopDefault(import('../pages/car/km0/_make/_model/_version/_config/_vehicle_uid/index.vue' /* webpackChunkName: "pages/car/km0/_make/_model/_version/_config/_vehicle_uid/index" */))
const _5239b290 = () => interopDefault(import('../pages/car/vo/_make/_model/_version/_config/_vehicle_uid/index.vue' /* webpackChunkName: "pages/car/vo/_make/_model/_version/_config/_vehicle_uid/index" */))
const _40f1fa20 = () => interopDefault(import('../pages/car/_make/index.vue' /* webpackChunkName: "pages/car/_make/index" */))
const _d3344f4e = () => interopDefault(import('../pages/news/_category/index.vue' /* webpackChunkName: "pages/news/_category/index" */))
const _2d4336f7 = () => interopDefault(import('../pages/alerts/_uid/create.vue' /* webpackChunkName: "pages/alerts/_uid/create" */))
const _5b1fb166 = () => interopDefault(import('../pages/alerts/_uid/unsubscribe.vue' /* webpackChunkName: "pages/alerts/_uid/unsubscribe" */))
const _2556435e = () => interopDefault(import('../pages/order/_order/finance-updated.vue' /* webpackChunkName: "pages/order/_order/finance-updated" */))
const _698f15ac = () => interopDefault(import('../pages/order/_order/under-study.vue' /* webpackChunkName: "pages/order/_order/under-study" */))
const _7d85149a = () => interopDefault(import('../pages/order/_order/bank-transfer-booked.vue' /* webpackChunkName: "pages/order/_order/bank-transfer-booked" */))
const _577031d1 = () => interopDefault(import('../pages/order/_order/finance-requested.vue' /* webpackChunkName: "pages/order/_order/finance-requested" */))
const _9fbaa6d0 = () => interopDefault(import('../pages/order/_order/payment-choose.vue' /* webpackChunkName: "pages/order/_order/payment-choose" */))
const _7a08b57f = () => interopDefault(import('../pages/car/_make/_model/index.vue' /* webpackChunkName: "pages/car/_make/_model/index" */))
const _1e1ec5da = () => interopDefault(import('../pages/news/_category/_uid.vue' /* webpackChunkName: "pages/news/_category/_uid" */))
const _4932107e = () => interopDefault(import('../pages/motorbike/_make/_model/_version/index.vue' /* webpackChunkName: "pages/motorbike/_make/_model/_version/index" */))
const _2e3652d2 = () => interopDefault(import('../pages/_uid/payment-choose.vue' /* webpackChunkName: "pages/_uid/payment-choose" */))
const _4cdfbd12 = () => interopDefault(import('../pages/_uid/request-booking.vue' /* webpackChunkName: "pages/_uid/request-booking" */))
const _39475c13 = () => interopDefault(import('../pages/_uid/request-finance.vue' /* webpackChunkName: "pages/_uid/request-finance" */))
const _53b894f4 = () => interopDefault(import('../pages/_uid/waiting-room.vue' /* webpackChunkName: "pages/_uid/waiting-room" */))
const _65df2d74 = () => interopDefault(import('../pages/_country/select-language.vue' /* webpackChunkName: "pages/_country/select-language" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/astara-selection",
    component: _f6d781f6,
    name: "astara-selection___es_ES"
  }, {
    path: "/blackfriday",
    component: _64f02862,
    name: "black-friday___es_ES"
  }, {
    path: "/busquedas-guardadas",
    component: _7f6c2791,
    name: "saved-searches___es_ES"
  }, {
    path: "/consentimiento-comunicaciones-comerciales",
    component: _4bc31f28,
    name: "consent-commercial-communications___es_ES"
  }, {
    path: "/contacto",
    component: _475d9f3b,
    name: "contact___es_ES"
  }, {
    path: "/descubre-tu-presupuesto",
    component: _29c1fd68,
    name: "buying-power___es_ES"
  }, {
    path: "/es_EN",
    component: _049e3fad,
    name: "index___es_EN"
  }, {
    path: "/etiqueta-cero",
    component: _212eecda,
    name: "etiqueta-cero___es_ES"
  }, {
    path: "/favoritos",
    component: _ff8b51c2,
    name: "favourites___es_ES"
  }, {
    path: "/financiacion",
    component: _103670f2,
    name: "financing___es_ES"
  }, {
    path: "/garantia",
    component: _0c370d71,
    name: "warranty___es_ES"
  }, {
    path: "/iniciar-sesion",
    component: _1076f653,
    name: "sign-in___es_ES"
  }, {
    path: "/mantenimiento",
    component: _632e79a4,
    name: "maintenance___es_ES"
  }, {
    path: "/motorbikes",
    component: _0d1dc315,
    name: "motorbikes___es_ES"
  }, {
    path: "/news",
    component: _5b44facb,
    name: "news___es_ES"
  }, {
    path: "/politica-de-cookies",
    component: _001a5236,
    name: "cookies-policy___es_ES"
  }, {
    path: "/politica-de-privacidad",
    component: _7ade5f68,
    name: "privacy-policy___es_ES"
  }, {
    path: "/preguntas-frecuentes",
    component: _79f7e292,
    name: "faqs___es_ES"
  }, {
    path: "/recuperar-contrasena",
    component: _6c97b936,
    name: "password-recovery___es_ES"
  }, {
    path: "/registro",
    component: _d2d7c76e,
    name: "sign-up___es_ES"
  }, {
    path: "/salonvo",
    component: _51c1863e,
    name: "salon-vo___es_ES"
  }, {
    path: "/select-country",
    component: _134a19fa,
    name: "select-country"
  }, {
    path: "/slice-simulator",
    component: _6b50fdb8,
    name: "slice-simulator___es_ES"
  }, {
    path: "/sobre-nosotros",
    component: _36333630,
    name: "about___es_ES"
  }, {
    path: "/tasacion-coche",
    component: _bd3a84ba,
    name: "trade-in___es_ES"
  }, {
    path: "/terminos-de-uso",
    component: _bf6f60c8,
    name: "use-terms___es_ES"
  }, {
    path: "/vehiculos-eco",
    component: _c6898666,
    name: "vehiculos-eco___es_ES"
  }, {
    path: "/alertas/nueva",
    component: _6c32995d,
    name: "alerts-new___es_ES"
  }, {
    path: "/astara-selection/cars",
    component: _9d565882,
    name: "astara-selection-cars___es_ES"
  }, {
    path: "/cars/km0",
    component: _4feec7d6,
    name: "cars-km0___es_ES"
  }, {
    path: "/cars/vn",
    component: _ffcfc9f4,
    name: "cars-vn___es_ES"
  }, {
    path: "/cars/vo",
    component: _14fccdc7,
    name: "cars-vo___es_ES"
  }, {
    path: "/cliente/ajustes",
    component: _1fee3ee2,
    name: "customer-settings___es_ES"
  }, {
    path: "/cliente/detalles",
    component: _32a9be06,
    name: "customer-details___es_ES"
  }, {
    path: "/cliente/pedidos",
    component: _13b42114,
    name: "customer-orders___es_ES"
  }, {
    path: "/descubre-tu-presupuesto/resultados",
    component: _6f4cb1f0,
    name: "buying-power-results___es_ES"
  }, {
    path: "/es_EN/about-us",
    component: _36333630,
    name: "about___es_EN"
  }, {
    path: "/es_EN/astara-selection",
    component: _f6d781f6,
    name: "astara-selection___es_EN"
  }, {
    path: "/es_EN/blackfriday",
    component: _64f02862,
    name: "black-friday___es_EN"
  }, {
    path: "/es_EN/consent-commercial-communications",
    component: _4bc31f28,
    name: "consent-commercial-communications___es_EN"
  }, {
    path: "/es_EN/contact",
    component: _475d9f3b,
    name: "contact___es_EN"
  }, {
    path: "/es_EN/cookies-policy",
    component: _001a5236,
    name: "cookies-policy___es_EN"
  }, {
    path: "/es_EN/discover-your-budget",
    component: _29c1fd68,
    name: "buying-power___es_EN"
  }, {
    path: "/es_EN/eco-vehicles",
    component: _c6898666,
    name: "vehiculos-eco___es_EN"
  }, {
    path: "/es_EN/faqs",
    component: _79f7e292,
    name: "faqs___es_EN"
  }, {
    path: "/es_EN/favourites",
    component: _ff8b51c2,
    name: "favourites___es_EN"
  }, {
    path: "/es_EN/financing",
    component: _103670f2,
    name: "financing___es_EN"
  }, {
    path: "/es_EN/login",
    component: _1076f653,
    name: "sign-in___es_EN"
  }, {
    path: "/es_EN/maintenance",
    component: _632e79a4,
    name: "maintenance___es_EN"
  }, {
    path: "/es_EN/motorbikes",
    component: _0d1dc315,
    name: "motorbikes___es_EN"
  }, {
    path: "/es_EN/news",
    component: _5b44facb,
    name: "news___es_EN"
  }, {
    path: "/es_EN/password-recovery",
    component: _6c97b936,
    name: "password-recovery___es_EN"
  }, {
    path: "/es_EN/privacy-policy",
    component: _7ade5f68,
    name: "privacy-policy___es_EN"
  }, {
    path: "/es_EN/salonvo",
    component: _51c1863e,
    name: "salon-vo___es_EN"
  }, {
    path: "/es_EN/saved-searches",
    component: _7f6c2791,
    name: "saved-searches___es_EN"
  }, {
    path: "/es_EN/signup",
    component: _d2d7c76e,
    name: "sign-up___es_EN"
  }, {
    path: "/es_EN/slice-simulator",
    component: _6b50fdb8,
    name: "slice-simulator___es_EN"
  }, {
    path: "/es_EN/trade-in",
    component: _bd3a84ba,
    name: "trade-in___es_EN"
  }, {
    path: "/es_EN/use-terms",
    component: _bf6f60c8,
    name: "use-terms___es_EN"
  }, {
    path: "/es_EN/warranty",
    component: _0c370d71,
    name: "warranty___es_EN"
  }, {
    path: "/es_EN/zero-emission",
    component: _212eecda,
    name: "etiqueta-cero___es_EN"
  }, {
    path: "/iniciar-sesion/otp",
    component: _230193ef,
    name: "sign-in-otp___es_ES"
  }, {
    path: "/ofertas/showroomprive",
    component: _7b7a3dad,
    name: "campaigns-showroomprive___es_ES"
  }, {
    path: "/ofertas/summer-days",
    component: _1539f338,
    name: "campaigns-summer-days___es_ES"
  }, {
    path: "/recuperar-contrasena/actualizar",
    component: _46737f47,
    name: "password-recovery-edit___es_ES"
  }, {
    path: "/recuperar-contrasena/completado",
    component: _31447d46,
    name: "password-recovery-update___es_ES"
  }, {
    path: "/registro/completado",
    component: _63a3b795,
    name: "sign-up-create___es_ES"
  }, {
    path: "/es_EN/alerts/new",
    component: _6c32995d,
    name: "alerts-new___es_EN"
  }, {
    path: "/es_EN/astara-selection/cars",
    component: _9d565882,
    name: "astara-selection-cars___es_EN"
  }, {
    path: "/es_EN/cars/km0",
    component: _4feec7d6,
    name: "cars-km0___es_EN"
  }, {
    path: "/es_EN/cars/vn",
    component: _ffcfc9f4,
    name: "cars-vn___es_EN"
  }, {
    path: "/es_EN/cars/vo",
    component: _14fccdc7,
    name: "cars-vo___es_EN"
  }, {
    path: "/es_EN/customer/details",
    component: _32a9be06,
    name: "customer-details___es_EN"
  }, {
    path: "/es_EN/customer/orders",
    component: _13b42114,
    name: "customer-orders___es_EN"
  }, {
    path: "/es_EN/customer/settings",
    component: _1fee3ee2,
    name: "customer-settings___es_EN"
  }, {
    path: "/es_EN/discover-your-budget/results",
    component: _6f4cb1f0,
    name: "buying-power-results___es_EN"
  }, {
    path: "/es_EN/login/otp",
    component: _230193ef,
    name: "sign-in-otp___es_EN"
  }, {
    path: "/es_EN/offers/showroomprive",
    component: _7b7a3dad,
    name: "campaigns-showroomprive___es_EN"
  }, {
    path: "/es_EN/offers/summer-days",
    component: _1539f338,
    name: "campaigns-summer-days___es_EN"
  }, {
    path: "/es_EN/password-recovery/update",
    component: _46737f47,
    name: "password-recovery-edit___es_EN"
  }, {
    path: "/es_EN/recuperar-contrasena/done",
    component: _31447d46,
    name: "password-recovery-update___es_EN"
  }, {
    path: "/es_EN/signup/done",
    component: _63a3b795,
    name: "sign-up-create___es_EN"
  }, {
    path: "/iniciar-sesion/otp/completado",
    component: _01a51dfc,
    name: "sign-in-otp-update___es_ES"
  }, {
    path: "/iniciar-sesion/otp/expirado",
    component: _52496382,
    name: "sign-in-otp-expired___es_ES"
  }, {
    path: "/es_EN/iniciar-sesion/otp/done",
    component: _01a51dfc,
    name: "sign-in-otp-update___es_EN"
  }, {
    path: "/es_EN/login/otp/expired",
    component: _52496382,
    name: "sign-in-otp-expired___es_EN"
  }, {
    path: "/",
    component: _049e3fad,
    name: "index___es_ES"
  }, {
    path: "/es_EN/customer/orders/:order",
    component: _6e5c9806,
    name: "customer-orders-order___es_EN"
  }, {
    path: "/es_EN/car/new/:make/:model",
    component: _2314b446,
    name: "car-vn-make-model___es_EN"
  }, {
    path: "/es_EN/car/new/:make/:model/:version",
    component: _5b6d8518,
    name: "car-vn-make-model-version___es_EN"
  }, {
    path: "/es_EN/astara-selection/car/:make/:model/:version/:config/:vehicle_uid",
    component: _41a8453a,
    name: "astara-selection-car-make-model-version-config-vehicle_uid___es_EN"
  }, {
    path: "/es_EN/car/km0/:make/:model/:version/:config/:vehicle_uid",
    component: _3559b01a,
    name: "car-km0-make-model-version-config-vehicle_uid___es_EN"
  }, {
    path: "/es_EN/car/second-hand/:make/:model/:version/:config/:vehicle_uid",
    component: _5239b290,
    name: "car-vo-make-model-version-config-vehicle_uid___es_EN"
  }, {
    path: "/cliente/pedidos/:order",
    component: _6e5c9806,
    name: "customer-orders-order___es_ES"
  }, {
    path: "/es_EN/car/:make",
    component: _40f1fa20,
    name: "car-make___es_EN"
  }, {
    path: "/es_EN/news/:category",
    component: _d3344f4e,
    name: "news-category___es_EN"
  }, {
    path: "/es_EN/alerts/:uid/resume",
    component: _2d4336f7,
    name: "alerts-uid-create___es_EN"
  }, {
    path: "/es_EN/alerts/:uid/unsubscribe",
    component: _5b1fb166,
    name: "alerts-uid-unsubscribe___es_EN"
  }, {
    path: "/es_EN/order/:order?/finance-updated",
    component: _2556435e,
    name: "order-order-finance-updated___es_EN"
  }, {
    path: "/es_EN/order/:order?/under-study",
    component: _698f15ac,
    name: "order-order-under-study___es_EN"
  }, {
    path: "/es_EN/order/:order/bank-transfer-booked",
    component: _7d85149a,
    name: "order-order-bank-transfer-booked___es_EN"
  }, {
    path: "/es_EN/order/:order/finance-requested",
    component: _577031d1,
    name: "order-order-finance-requested___es_EN"
  }, {
    path: "/es_EN/order/:order/payment-choose",
    component: _9fbaa6d0,
    name: "order-order-payment-choose___es_EN"
  }, {
    path: "/coche/nuevo/:make/:model",
    component: _2314b446,
    name: "car-vn-make-model___es_ES"
  }, {
    path: "/es_EN/car/:make/:model",
    component: _7a08b57f,
    name: "car-make-model___es_EN"
  }, {
    path: "/es_EN/news/:category/:uid",
    component: _1e1ec5da,
    name: "news-category-uid___es_EN"
  }, {
    path: "/coche/nuevo/:make/:model/:version",
    component: _5b6d8518,
    name: "car-vn-make-model-version___es_ES"
  }, {
    path: "/es_EN/motorcycle/:make/:model/:version",
    component: _4932107e,
    name: "motorbike-make-model-version___es_EN"
  }, {
    path: "/astara-selection/coche/:make/:model/:version/:config/:vehicle_uid",
    component: _41a8453a,
    name: "astara-selection-car-make-model-version-config-vehicle_uid___es_ES"
  }, {
    path: "/coche/km0/:make/:model/:version/:config/:vehicle_uid",
    component: _3559b01a,
    name: "car-km0-make-model-version-config-vehicle_uid___es_ES"
  }, {
    path: "/coche/segunda-mano/:make/:model/:version/:config/:vehicle_uid",
    component: _5239b290,
    name: "car-vo-make-model-version-config-vehicle_uid___es_ES"
  }, {
    path: "/coche/:make",
    component: _40f1fa20,
    name: "car-make___es_ES"
  }, {
    path: "/news/:category",
    component: _d3344f4e,
    name: "news-category___es_ES"
  }, {
    path: "/alertas/:uid/eliminar",
    component: _5b1fb166,
    name: "alerts-uid-unsubscribe___es_ES"
  }, {
    path: "/alertas/:uid/resumen",
    component: _2d4336f7,
    name: "alerts-uid-create___es_ES"
  }, {
    path: "/es_EN/:uid/payment-choose",
    component: _2e3652d2,
    name: "uid-payment-choose___es_EN"
  }, {
    path: "/es_EN/:uid/request-booking",
    component: _4cdfbd12,
    name: "uid-request-booking___es_EN"
  }, {
    path: "/es_EN/:uid/request-finance",
    component: _39475c13,
    name: "uid-request-finance___es_EN"
  }, {
    path: "/es_EN/:uid/waiting-room",
    component: _53b894f4,
    name: "uid-waiting-room___es_EN"
  }, {
    path: "/order/:order?/finance-updated",
    component: _2556435e,
    name: "order-order-finance-updated___es_ES"
  }, {
    path: "/order/:order?/under-study",
    component: _698f15ac,
    name: "order-order-under-study___es_ES"
  }, {
    path: "/order/:order/bank-transfer-booked",
    component: _7d85149a,
    name: "order-order-bank-transfer-booked___es_ES"
  }, {
    path: "/order/:order/finance-requested",
    component: _577031d1,
    name: "order-order-finance-requested___es_ES"
  }, {
    path: "/order/:order/payment-choose",
    component: _9fbaa6d0,
    name: "order-order-payment-choose___es_ES"
  }, {
    path: "/coche/:make/:model",
    component: _7a08b57f,
    name: "car-make-model___es_ES"
  }, {
    path: "/news/:category/:uid",
    component: _1e1ec5da,
    name: "news-category-uid___es_ES"
  }, {
    path: "/moto/:make/:model/:version",
    component: _4932107e,
    name: "motorbike-make-model-version___es_ES"
  }, {
    path: "/:country/select-language",
    component: _65df2d74,
    name: "country-select-language"
  }, {
    path: "/:uid/eleccion-pago",
    component: _2e3652d2,
    name: "uid-payment-choose___es_ES"
  }, {
    path: "/:uid/solicitar-financiacion",
    component: _39475c13,
    name: "uid-request-finance___es_ES"
  }, {
    path: "/:uid/solicitar-reserva",
    component: _4cdfbd12,
    name: "uid-request-booking___es_ES"
  }, {
    path: "/:uid/waiting-room",
    component: _53b894f4,
    name: "uid-waiting-room___es_ES"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
