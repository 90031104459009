<template>
  <nav
    ref="swiper"
    :class="[
      this.theme,
      'navbar-scrollable',
      `navbar-scrollable--${this.theme}`,
      'swiper',
      {
        'navbar-scrollable--static': isStatic,
      },
    ]"
  >
    <div class="swiper-wrapper">
      <div
        v-for="option in options"
        :key="`${componentUid}_${option.key}`"
        data-cy="selection-element"
        :class="`navbar-scrollable__item swiper-slide selection-element-${option.key}`"
        @click="() => onSelectOption(option.key)"
      >
        <div
          :id="option.key"
          :class="[
            'navbar-scrollable__item__tab',
            {
              'navbar-scrollable__item__tab--current': selected === option.key,
            },
          ]"
          data-cy="scrollable-option"
        >
          <span data-cy="selection-title" class="title medium f-size-14 f-uppercase">
            {{ option?.value || $t(`support.options.${option.key}`) }}
          </span>
          <span v-if="option?.hint" class="subtitle f-size-12 f-secondary">
            {{ option.hint }}
          </span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
// Dependencies
import { Swiper } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'

// Other
const THEMES = ['transparent', 'primary', 'secondary', 'tertiary']

export default {
  name: 'NavbarScrolled',
  props: {
    options: {
      type: Array,
      required: true,
    },
    selection: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      validator: (value) => THEMES.includes(value),
      default: 'primary',
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: this.selection,
      swiper: null,
      lock: false,
      clicked: null,
      scrollTimer: null,
      componentUid: undefined,
    }
  },
  methods: {
    setupSlider() {
      this.swiper = new Swiper(this.$refs.swiper, {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        centeredSlides: false,
        centeredSlidesBounds: false,
        breakpoints: {
          // >= 480px
          480: {
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
        },
      })

      this.swiper.on('click', () => {
        if (window.innerWidth <= 480) {
          this.swiper.slideTo(this.swiper.clickedIndex)
        }
      })
    },
    onSelectOption(key) {
      this.lock = true
      this.clicked = key
      this.selected = key
      this.$emit('submit', key)
    },
    onScroll() {
      if (this.scrollTimer !== null) {
        clearTimeout(this.scrollTimer)
      }

      this.scrollTimer = window.setTimeout(this.onScrollEnd, 100)
    },
    onScrollEnd() {
      this.lock = false
    },
  },
  watch: {
    selection(val) {
      if (!this.lock) {
        this.selected = val

        if (window.innerWidth <= 480) {
          const slides = document.querySelectorAll('.navbar-scrollable__item__tab')

          if (!this.isStatic) {
            for (let i = 0; i < slides.length; ++i) {
              if (slides[i].id === this.selected) {
                this.swiper.slideTo(i)
                break
              }
            }
          }
        }
      }
    },
  },
  created() {
    this.componentUid = this._uid
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)

    if (!this.isStatic) {
      window.addEventListener('resize', this.setupSlider)
      this.setupSlider()
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.setupSlider)
  },
}
</script>

<style lang="scss" scoped>
.swiper {
  overflow: visible;
}

.navbar-scrollable {
  border-radius: 0.5rem;
  color: $text-secondary;

  &--transparent .navbar-scrollable__item {
    background-color: transparent;
  }

  &--primary .navbar-scrollable__item {
    background-color: $c-neutral-500;
  }

  &--secondary .navbar-scrollable__item {
    background: rgba(50, 25, 25, 0.1);
    color: $text-primary;
  }

  &--tertiary .navbar-scrollable__item {
    width: 50%;
    background-color: $c-neutral-500;

    .swiper-wrapper {
      box-sizing: content-box;
      flex-direction: row;
      flex-wrap: wrap;
      transition-property: transform;
    }

    &__tab {
      padding: 0 4rem;

      @media (width <= 1355px) {
        padding: 0 3rem;
      }

      @media (width <= 1065px) {
        padding: 0 2.5rem;
      }
    }
  }

  &__item {
    display: inline-block;
    width: auto;
    height: unset;
    padding: 0.1875rem;
    cursor: pointer;

    &:first-child {
      border-radius: 0.5rem 0 0 0.5rem;
    }

    &:last-child {
      border-radius: 0 0.5rem 0.5rem 0;
    }

    * {
      pointer-events: none;
    }

    &__tab {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 2.25rem;
      padding: 0.375rem 1rem;
      border: 0.0625rem solid transparent;
      line-height: 1.25rem;
      text-align: center;

      @include size-s {
        padding: 0.375rem 0.5rem;
      }

      &--current {
        border-radius: 0.5rem;
        border-color: $c-neutral-700;
        background-color: $c-white;
        box-shadow: $c-elevation-1;
        color: $text-primary;
      }

      .subtitle {
        @include size-s {
          font-size: 0.625rem;
          line-height: 0.875rem;
        }
      }
    }
  }

  &--static {
    .swiper-wrapper {
      transform: none !important;
    }
  }
}
</style>
