<template>
  <Modal
    :label="$t('modals.contact.form_title')"
    :open="open"
    :subtitle="isNoStock && !success && !error ? $t('modals.contact.no_stock.title') : undefined"
    :enable-prevent-body-scroll="!inline"
    :full-screen-on-mobile="!inline"
    :class="[
      'contact-form-modal',
      {
        'contact-form-modal--inline': inline,
        'contact-form-modal--success': success,
      },
    ]"
    name="contact-form-modal"
    v-on="$listeners"
    @close="() => $emit('close')"
  >
    <Notification
      v-if="error"
      :message="$t(`messages.error.${error}.message`)"
      :title="
        $te(`messages.error.${error}.title`) ? $t(`messages.error.${error}.title`) : undefined
      "
      class="contact-form-modal__error"
      displayed
      error
      inline
      @close="() => (error = '')"
    />

    <ValidationObserver v-if="!success" v-slot="{ validate }">
      <form class="contact-form-modal__form" @submit.prevent="onSubmit(validate)">
        <ValidationProvider
          v-slot="{ touched, errors }"
          :class="['name', { 'third-width-field': !inline, 'half-width-field': inline }]"
          :name="$t('modals.contact.name')"
          :skip-if-empty="false"
          rules="required|max:40"
        >
          <Input
            v-model="formState.name"
            :error="touched ? errors[0] : null"
            :label="$t('modals.contact.name')"
            :maxlength="40"
            :placeholder="$t('modals.contact.name_placeholder')"
            :size="inline || !isSizeMUp ? 'm' : 's'"
            data-cy="name-input"
            name="name"
            required
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ touched, errors }"
          :class="['last-name', { 'third-width-field': !inline, 'half-width-field': inline }]"
          :name="$t('modals.contact.last_name')"
          :skip-if-empty="false"
          rules="required|max:80"
        >
          <Input
            v-model="formState.lastName"
            :error="touched ? errors[0] : null"
            :label="$t('modals.contact.last_name')"
            :maxlength="80"
            :placeholder="$t('modals.contact.last_name_placeholder')"
            :size="inline || !isSizeMUp ? 'm' : 's'"
            data-cy="last_name-input"
            name="last_name"
            required
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ touched, errors }"
          :name="$t('modals.contact.second_last_name')"
          :skip-if-empty="false"
          rules="max:80"
          :class="[
            'second-last-name',
            {
              'third-width-field': !inline,
              'half-width-field': inline,
            },
          ]"
        >
          <Input
            v-model="formState.secondLastName"
            :error="touched ? errors[0] : null"
            :label="$t('modals.contact.second_last_name')"
            :maxlength="80"
            :placeholder="$t('modals.contact.second_last_name_placeholder')"
            :size="inline || !isSizeMUp ? 'm' : 's'"
            data-cy="second_last_name-input"
            name="second_last_name"
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ touched, errors }"
          :skip-if-empty="false"
          :name="$t('modals.contact.email')"
          rules="required|email"
          :class="[
            'email',
            {
              'full-width-field': isNoStock || inline,
              'half-width-field': !inline,
            },
          ]"
        >
          <Input
            v-model="formState.email"
            :error="touched ? errors[0] : undefined"
            :label="$t('modals.contact.email')"
            :placeholder="$t('modals.contact.email_placeholder')"
            :hint="$t('modals.contact.email_hint')"
            name="email"
            :size="inline || !isSizeMUp ? 'm' : 's'"
            type="email"
            append-icon="email"
            required
            data-cy="email-input"
          />
        </ValidationProvider>

        <ValidationProvider
          v-if="!isNoStock"
          v-slot="{ touched, errors }"
          :skip-if-empty="false"
          :name="$t('modals.contact.phone')"
          :rules="{
            required: true,
            regex: /^(\+34|0034|34)?[6789]\d{8}$/,
          }"
          class="half-width-field phone"
        >
          <Input
            v-model="formState.phone"
            :label="$t('modals.contact.phone')"
            :placeholder="$t('modals.contact.phone_placeholder')"
            name="phone"
            :size="inline || !isSizeMUp ? 'm' : 's'"
            :error="touched ? errors[0] : null"
            append-icon="phone"
            required
            data-cy="phone-input"
          />
        </ValidationProvider>

        <ValidationProvider
          v-if="isTradeIn"
          v-slot="{ errors }"
          :skip-if-empty="true"
          name="trade_in"
          class="full-width-field trade-in-check"
        >
          <Checkbox
            v-model="formState.tradeIn"
            name="trade_in_check"
            :error="errors[0]"
            data-cy="ecomm-checkbox"
          >
            <span>{{ $t('modals.contact.trade_in') }}</span>
          </Checkbox>
        </ValidationProvider>

        <ValidationProvider
          v-if="!isNoStock"
          v-slot="{ touched, errors }"
          :skip-if-empty="true"
          :name="$t('modals.contact.message')"
          class="full-width-field message"
        >
          <Textarea
            v-model="formState.message"
            :label="$t('modals.contact.message')"
            :placeholder="$t('modals.contact.message_placeholder')"
            :hint="$t('modals.contact.message_hint')"
            name="message"
            :error="touched ? errors[0] : null"
            data-cy="message-input"
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          :skip-if-empty="false"
          :rules="{ required: { allowFalse: false } }"
          :name="$t('global.terms_of_service.label')"
          class="full-width-field terms-of-service"
        >
          <Checkbox
            v-model="formState.termsOfServiceAccepted"
            name="terms_of_service_accepted"
            :error="errors[0]"
            :label="
              $t('global.terms_of_service.html', {
                use_terms_url: localePath('use-terms'),
                privacy_policy_url: localePath('privacy-policy'),
              })
            "
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          :skip-if-empty="true"
          :name="$t('global.commercial_information.label')"
          class="full-width-field terms-of-service"
        >
          <Checkbox
            v-model="formState.commercialInformationConsented"
            name="communication_accepted"
            :rules="{ required: { allowFalse: true } }"
            :error="errors[0]"
            :label="$t('global.commercial_information.text')"
          />
        </ValidationProvider>

        <button v-show="false" ref="submit" />
      </form>
    </ValidationObserver>

    <Notification
      v-else
      inline
      light
      displayed
      success
      :can-be-closed="false"
      :title="$t('messages.success.contact_form.title')"
      class="contact-form-modal__success"
    >
      <template v-slot:message>
        <p class="f-size-18">
          <template v-if="isNoStock">
            {{ $t('messages.success.contact_form.message_no_stock') }}
          </template>
          <template v-else>
            {{ $t('messages.success.contact_form.message') }}
          </template>
        </p>
        <ul v-if="!isNoStock">
          <li class="f-size-18 f-500 f-highlighted">
            {{ $t('messages.success.contact_form.schedule') }}
          </li>
        </ul>
      </template>
    </Notification>

    <template v-slot:actions>
      <Button
        :data-cy="success ? 'success-close-button' : 'form-send-button'"
        :loading="loading"
        :type-theme="inline ? 'secondary-light' : 'primary-light'"
        size="large"
        @click="() => onPerformSubmit()"
      >
        {{
          success
            ? $t('messages.success.contact_form.submit')
            : isNoStock
            ? $t('modals.contact.no_stock.submit')
            : $t('modals.contact.submit')
        }}
      </Button>
    </template>
  </Modal>
</template>

<script>
// Dependencies
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// Helpers & mixins
import checkViewportSize from '@/mixins/checkViewportSize'
import { getFullPageUrl } from '@/helpers/urls'

// Components
import Button from '@/components/Button.vue'
import Checkbox from '@/components/Checkbox.vue'
import Input from '@/components/Input.vue'
import Modal from '@/components/Modal.vue'
import Notification from '@/components/Notification.vue'
import Textarea from '@/components/Textarea.vue'

// Other
const TYPES = [undefined, 'no_stock']

export default {
  name: 'ModalsContact',
  components: {
    Button,
    Checkbox,
    Input,
    Modal,
    Notification,
    Textarea,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [checkViewportSize],
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    vehicleData: {
      type: [Object, undefined],
      required: false,
      default: undefined,
    },
    modalLabel: {
      type: String,
      required: false,
      default: undefined,
    },
    modalTitle: {
      type: String,
      required: false,
      default: undefined,
    },
    leadTags: {
      type: String,
      required: false,
      default: undefined,
    },
    type: {
      type: [String, undefined],
      required: false,
      validator: (value) => TYPES.includes(value),
      default: '',
    },
    financialOnboardingUrl: {
      type: String,
      required: false,
      default: undefined,
    },
    defaultMessage: {
      type: String,
      required: false,
      default: '',
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      error: '',
      loading: false,
      origin: this.type === 'no_stock' ? 'no_stock' : 'contact_form',
      success: false,
      formState: {
        commercialInformationConsented: false,
        email: '',
        lastName: '',
        message: this.defaultMessage,
        name: '',
        phone: '',
        secondLastName: '',
        termsOfServiceAccepted: false,
        tradeIn: false,
      },
    }
  },
  computed: {
    isTradeIn() {
      return this.type === 'trade_in'
    },
    isNoStock() {
      return this.type === 'no_stock'
    },
  },
  methods: {
    onOpen() {
      if (!this.inline) {
        this.$tracker.contactFormView({
          ...this.formState,
          make: this.vehicleData ? this.vehicleData.make : null,
          model: this.vehicleData ? this.vehicleData.model : null,
          version: this.vehicleData ? this.vehicleData.version : null,
          contact_reason: this.vehicleData
            ? `${this.vehicleData.make} ${this.vehicleData.model} ${this.vehicleData.version}`
            : null,
          vehicle_type: this.vehicleData
            ? ['moto', 'motorbike'].includes(this.vehicleData.vehicle_category)
              ? 'motorbike'
              : 'car'
            : null,
          origin: this.origin,
          product_condition: this.vehicleData
            ? this.$t(
                `global.datalayers_categories.${this.vehicleData.vehicle_type.toLowerCase()}_${this.vehicleData.category.toLowerCase()}`,
              )
            : null,
        })
      }
    },
    onPerformSubmit() {
      if (this.success) {
        this.$emit('close')
      } else {
        this.$refs.submit.click()
      }
    },
    async onSubmit(validate) {
      this.loading = true
      this.error = ''

      await validate().then(async (isValid) => {
        if (!isValid) {
          this.error = 'invalid_fields'
          this.loading = false
        } else {
          try {
            const payload = {
              commercial_information_consented: this.formState.commercialInformationConsented,
              details: null,
              email: this.formState.email,
              last_name: this.formState.lastName,
              message: this.formState.message,
              name: this.formState.name,
              origin_url: getFullPageUrl(this.$route, true, false),
              origin: this.origin,
              phone: this.formState.phone,
              second_last_name: this.formState.secondLastName,
              terms_of_service_accepted: this.formState.termsOfServiceAccepted,
              meta: {
                origin_process: 'Contact',
                origin: this.$store.getters['campaign/getSource'] || 'Website',
                medium: this.$store.getters['campaign/getMedium'],
                campaign: this.$store.getters['campaign/getCampaign'],
                url_path: getFullPageUrl(this.$route, true, false),
                legalTratamientoDatos: this.$t('global.terms_of_service.text'),
                legalComunicaciones: this.$t('global.commercial_information.text'),
                financial_onboarding_url: this.financialOnboardingUrl
                  ? this.financialOnboardingUrl
                  : getFullPageUrl(this.$route, true, false),
              },
            }

            if (this.formState.tradeIn) {
              payload.message += ` \n${this.$t('modals.contact.trade_in')}`
            }

            if (this.leadTags) {
              payload.message += ` ${this.leadTags}`
            }

            if (this.vehicleData && Object.keys(this.vehicleData).length) {
              payload.brand = this.vehicleData.make
              payload.details = `${this.vehicleData.make} ${this.vehicleData.model} ${this.vehicleData.version}`
              payload.model = this.vehicleData.model
              payload.vehicle_category = this.vehicleData.category
              payload.version = this.vehicleData.version
              payload.meta.body_work = this.vehicleData.bodywork
              payload.meta.engine_type = this.vehicleData.engine_type
              payload.meta.fuel_type = this.vehicleData.fuel_type
              payload.meta.make = this.vehicleData.make
              payload.meta.model = this.vehicleData.model
              payload.meta.price = this.vehicleData.price
              payload.meta.vehicle_category = this.vehicleData.category
              payload.meta.vehicle_photo_url = this.vehicleData.vehicle_photo_url
              payload.meta.vehicle_type = this.vehicleData.vehicle_type?.toLowerCase()
              payload.meta.version = this.vehicleData.version

              if (this.isNoStock) {
                payload.vehicle = {
                  make: this.vehicleData?.makeSlug || null,
                  model: this.vehicleData?.modelSlug || null,
                  version: this.vehicleData?.versionSlug || null,
                  url_path: getFullPageUrl(this.$route, true, false),
                }
              }
            }

            await this.$leadService
              .sendLead(payload)
              .then(() => {
                this.$emit('formStatus', 'ok')
                this.success = true
              })
              .catch(() => {
                this.$emit('formStatus', 'ko')
                this.error = 'service'
              })
          } catch {
            this.$emit('formStatus', 'ko')
            this.error = 'service'
          } finally {
            this.$emit('submit', this.formState)
            this.loading = false

            // Ensures scroll to top
            document.querySelector('.contact-form-modal .modal__main').scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })

            document.querySelector('.contact-form-modal .modal-content-wrapper').scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })
          }
        }
      })
    },
  },
  watch: {
    open: {
      handler() {
        if (this.open) {
          this.onOpen()
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.open) {
      this.onOpen()
    }
  },
}
</script>

<style lang="scss" scoped>
.contact-form-modal :deep(.modal) {
  @include size-m-up {
    width: 100% !important;
  }

  @include size-l-up {
    width: 40.125rem !important;
  }

  @include size-xl-up {
    width: 50rem !important;
  }

  @include size-xxl-up {
    width: 55.5rem !important;
  }
}

.contact-form-modal :deep(.contact-form-modal__error) {
  margin-bottom: 1.5rem;
}

.contact-form-modal :deep(.contact-form-modal__form) {
  display: grid;
  grid-row-gap: 0.5rem;
  grid-template-columns: 1fr;
  width: 100%;

  .third-width-field {
    grid-column: span 2;
  }

  .half-width-field {
    grid-column: span 3;
  }

  *.full-width-field {
    grid-column: 1 / -1 !important;
  }

  @include size-m-up {
    grid-column-gap: 1.5rem;
    grid-template-columns: repeat(6, 1fr);
  }

  .input-wrapper,
  .textarea-wrapper {
    margin-top: 0 !important;
  }

  .checkbox-container {
    margin-top: 1rem !important;
  }

  .textarea {
    min-height: 5.75rem;
  }
}

.contact-form-modal :deep(.contact-form-modal__success) {
  overflow: visible !important;

  .notification__aside {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 0.25rem);
      border-radius: 50%;
      background-color: #e6f5ef;
      aspect-ratio: 1 / 1;
    }

    .icon {
      z-index: 1;
      transform: translate(0.5rem, 0.5rem);
      font-size: 1.5rem !important;
    }
  }

  .notification__body {
    padding-left: 1rem;
  }

  .notification__body__header__title {
    font-size: 2.25rem;
    font-weight: 500;
    line-height: 2.5rem;

    @include size-l {
      font-size: 2rem;
    }

    @include size-s {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  p {
    margin: 0;
  }

  ul {
    margin: 1rem 0 0;
    padding-left: 1.25rem;

    li {
      font-style: normal;
    }
  }
}

.contact-form-modal:not(.contact-form-modal--inline) {
  @include size-m-up {
    :deep(.input-wrapper) {
      height: 5rem;

      label {
        font-size: 0.75rem;
      }

      input {
        font-size: 0.875rem;
      }
    }

    :deep(.textarea-wrapper) {
      label {
        font-size: 0.75rem;
      }
      textarea {
        font-size: 0.875rem;
      }
    }
  }
}

.contact-form-modal--inline {
  position: relative !important;
  z-index: 1 !important;
  width: auto !important;
  height: auto !important;
  overflow: auto !important;
  background: none !important;

  * {
    overflow: visible !important;
  }

  :deep(.modal__header) {
    display: none !important;
  }

  :deep(.modal__main__actions) {
    margin-top: 2.5rem !important;
    padding: 0;
    border-top: 0;
    background: none !important;
  }

  :deep(.modal__main__content),
  :deep(.modal-content-wrapper),
  :deep(.modal) {
    padding: 0 !important;
  }

  :deep(.modal) {
    width: 100% !important;
    height: auto !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
    background: none !important;
  }

  :deep(.contact-form-modal__form) {
    grid-row-gap: 1rem;
  }

  .input-wrapper {
    height: auto !important;
  }

  .name {
    order: 1;
  }

  .last-name {
    order: 2;
  }

  .second-last-name {
    order: 3;
  }

  .phone {
    order: 4;
  }

  .email {
    grid-column: span 6;
    order: 5;
  }

  .trade-in-check {
    order: 6;
  }

  .message {
    order: 6;
  }

  .terms-of-service {
    order: 7;
  }

  .commercial-information-consented {
    order: 8;
  }
}
</style>
