export const state = () => ({
  user: undefined,
  favourites: [],
  softUser: undefined,
})

export const getters = {
  loggedIn(state) {
    return !!state.user
  },
  currentUser(state) {
    return state.user
  },
  favourites(state) {
    return state.favourites
  },
  softLoggedIn(state) {
    return !!state.softUser?.email
  },
  softUser(state) {
    return state.softUser
  },
}

export const mutations = {
  setUser(state, user) {
    state.user = user

    if (process.client) {
      const storedState = sessionStorage.getItem('astara_store')

      let currentState
      if (storedState) {
        currentState = JSON.parse(storedState)
      }

      sessionStorage.setItem(
        'astara_store',
        JSON.stringify({
          ...currentState,
          user,
        }),
      )
    }
  },
  setSoftUser(state, user) {
    state.softUser = user

    if (process.client) {
      const storedState = sessionStorage.getItem('astara_store')

      let currentState
      if (storedState) {
        currentState = JSON.parse(storedState)
      }

      sessionStorage.setItem(
        'astara_store',
        JSON.stringify({
          ...currentState,
          softUser: user,
        }),
      )
    }
  },
  setFavourites(state, favourites) {
    state.favourites = favourites
  },
}

const cookieOptions = ({ remember = false }) => {
  const options = { path: '/' }

  if (remember) {
    options.maxAge = 60 * 60 * 24 * 365
  }

  return options
}

export const actions = {
  initializeStore({ state, commit }) {
    if (process.client) {
      const storedState = sessionStorage.getItem('astara_store')
      let currentState

      if (storedState) {
        currentState = JSON.parse(storedState)
      }

      if (!Object.keys(state?.user || {}).length && !Object.keys(state?.softUser || {}).length) {
        if (this.$cookiez.get('token') && currentState && currentState.user) {
          commit('setUser', currentState.user)
        } else if (currentState && currentState.softUser) {
          commit('setSoftUser', currentState.softUser)
        }
      } else {
        const newState = {
          ...currentState,
          user: state.user,
          softUser: state.softUser,
        }
        sessionStorage.setItem('astara_store', JSON.stringify(newState))
      }
    }
  },
  async register({ dispatch }, params) {
    const { data, headers } = await this.$customerService.register(params)
    this.$axios.setHeader('Authorization', headers.authorization)
    this.$cookiez.set('token', headers.authorization, cookieOptions(true))
    await dispatch('refreshUser')

    return data
  },
  async login({ dispatch }, params) {
    const { data, headers } = await this.$customerService.login(params)
    this.$axios.setHeader('Authorization', headers.authorization)
    this.$cookiez.set('token', headers.authorization, cookieOptions(params))
    await dispatch('refreshUser')

    return data
  },
  async loginWithOtp({ commit }, params) {
    const { getResponseFromServer, ...payload } = params

    try {
      const response = await this.$customerService.loginWithOtp(payload)
      const token = response?.data?.token

      if (response.status === 200 && token) {
        this.$cookiez.set('token', `Bearer ${token}`, cookieOptions(payload))
        this.$axios.setHeader('Authorization', `Bearer ${token}`)
        const customer = await this.$axios.$get('/v1/me')
        commit('setUser', customer)
        commit('setSoftUser', null)

        return getResponseFromServer ? response : true
      }

      return getResponseFromServer ? response : false
    } catch (err) {
      return getResponseFromServer ? err : false
    }
  },
  async refreshToken({ dispatch, state }, { token }) {
    this.$axios.setToken(token)

    if (!process.client || !state.user) {
      await dispatch('refreshUser')
    }
  },
  async refreshUser({ commit, dispatch }) {
    // Set user data
    try {
      this.$axios.setHeader('Authorization', this.$cookiez.get('token'))
      const user = await this.$axios.$get('/v1/me')
      commit('setUser', user)
      commit('setSoftUser', undefined)

      // Set favourites
      const favourites = await this.$favouritesService.list()
      commit('setFavourites', favourites)
    } catch {
      await dispatch('logout')
    }
  },
  logout({ commit }) {
    commit('setUser', undefined)
    commit('setSoftUser', undefined)
    commit('setFavourites', [])

    if (process.client) {
      sessionStorage.removeItem('astara_store')
    }

    this.$cookiez.remove('token')
    this.$axios.setHeader('Authorization', undefined)
    this.$router.push(this.localePath('index')).catch(() => {})
  },
}
