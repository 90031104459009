import Vue from 'vue'

export default ({ app }, inject) => {
  inject('chatWith', Vue.observable({ loaded: false }))
  Vue.mixin({
    methods: {
      cleanChatwith() {
        const chat = document.querySelectorAll('.chatwithapp')
        if (chat && chat.length > 0) {
          chat.forEach((c) => {
            c.remove()
          })
        }
      },
    },
  })
}
